import { sendUtagEvent } from '../../core/analytics/utag_events'

export function initializeUtagEvents() {
  const isFhiPage = document.querySelector('html').dataset.fhiTest !== undefined
  const seeMoreNumberLink = document.querySelector('.phone-list-see-numbers.utag-event-call')
  const structure = ''
  seeMoreNumberLink.addEventListener('click', () => {
    const name = 'call_view_numbers'
    const category = 'call center'
    const action = 'view other number'
    const fhiPageType = document.querySelector('html').dataset.fhiTest

    sendUtagEvent({
      data: {
        event_name: name,
        event_cat: category,
        event_act: action,
        event_lbl: '',
        event_purpose: 'information',
        event_structure: 'popup',
        page_variation: isFhiPage ? fhiPageType : 'control',
      },
    })
  })

  document.querySelector('.utag-link-to-contact-iberostarpro').addEventListener('click', () => {
    sendUtagEvent({
      data: {
        event_name: 'call_agency',
        event_cat: 'call center',
        event_act: 'agency contact',
        event_lbl: '',
        event_purpose: 'information',
        event_structure: 'popup',
      },
    })
  })

  document.querySelector('.utag-link-to-contact-form').addEventListener('click', () => {
    const category = 'call center'
    const action = 'mail contact'
    const fhiPageType = document.querySelector('html').dataset.fhiTest

    if (!isFhiPage) {
      sendUtagEvent({
        data: {
          event_name: 'call_mail',
          event_cat: category,
          event_act: action,
          event_lbl: '',
          event_structure: 'popup',
          event_purpose: 'information',
        },
      })
    } else {
      sendUtagEvent({
        data: {
          event_name: 'call_mail',
          event_cat: category,
          event_act: action,
          event_lbl: '',
          event_structure: 'popup',
          event_purpose: 'information',
          page_variation: fhiPageType,
        },
      })
    }
  })
  document.querySelectorAll('.utag-show-call-me-back-modal').forEach(element => {
    element.addEventListener('click', function () {
      sendUtagEvent({
        data: {
          event_name: 'call_me_back',
          event_cat: 'call center',
          event_act: 'call me back',
          event_lbl: 'popup',
          event_purpose: 'information',
          event_structure: 'popup',
        },
      })
    })
  })
}
