import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import { setAsyncDayjsLocale } from '../../core/utils/locale'

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)

if (IB.currentDevice === 'mobile') {
  import(`./rangedatepicker_mobile.scss`)
} else {
  import(`./rangedatepicker.scss`)
}

// daterangepicker.js
// author : Chunlong Liu
// license : MIT
// www.jszen.com
// https://github.com/longbill/jquery-date-range-picker

;(function (factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery', 'dayjs'], factory)
  } else if (typeof exports === 'object' && typeof module !== 'undefined') {
    // CommonJS. Register as a module
    module.exports = factory(require('jquery'), require('dayjs'))
  } else {
    // Browser globals
    factory(jQuery, dayjs)
  }
})(function ($, dayjs) {
  $.dateRangePickerLanguages = {
    //default language: English
    default: {
      selected: 'Selected:',
      day: 'Day',
      days: 'Days',
      apply: 'Close',
      'week-1': 'mo',
      'week-2': 'tu',
      'week-3': 'we',
      'week-4': 'th',
      'week-5': 'fr',
      'week-6': 'sa',
      'week-7': 'su',
      'week-number': 'W',
      'month-name': [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
      ],
      shortcuts: 'Shortcuts',
      'custom-values': 'Custom Values',
      past: 'Past',
      following: 'Following',
      previous: 'Previous',
      'prev-week': 'Week',
      'prev-month': 'Month',
      'prev-year': 'Year',
      next: 'Next',
      'next-week': 'Week',
      'next-month': 'Month',
      'next-year': 'Year',
      'less-than': 'Date range should not be more than %d days',
      'more-than': 'Date range should not be less than %d days',
      'default-more': 'Please select a date range longer than %d days',
      'default-single': 'Please select a date',
      'default-less': 'Please select a date range less than %d days',
      'default-range': 'Please select a date range between %d and %d days',
      'default-default': 'Please select a date range',
      time: 'Time',
      hour: 'Hour',
      minute: 'Minute',
      entrada: 'Check-in date',
      salida: 'Check-out date',
      'current-year': '',
      'previous-month': '',
      'next-month': '',
      'selected-day': '',
    },
    az: {
      selected: 'Seçildi:',
      day: ' gün',
      days: ' gün',
      apply: 'tətbiq',
      'week-1': '1',
      'week-2': '2',
      'week-3': '3',
      'week-4': '4',
      'week-5': '5',
      'week-6': '6',
      'week-7': '7',
      'month-name': [
        'yanvar',
        'fevral',
        'mart',
        'aprel',
        'may',
        'iyun',
        'iyul',
        'avqust',
        'sentyabr',
        'oktyabr',
        'noyabr',
        'dekabr',
      ],
      shortcuts: 'Qısayollar',
      past: 'Keçmiş',
      following: 'Növbəti',
      previous: '&nbsp;&nbsp;&nbsp;',
      'prev-week': 'Öncəki həftə',
      'prev-month': 'Öncəki ay',
      'prev-year': 'Öncəki il',
      next: '&nbsp;&nbsp;&nbsp;',
      'next-week': 'Növbəti həftə',
      'next-month': 'Növbəti ay',
      'next-year': 'Növbəti il',
      'less-than': 'Tarix aralığı %d gündən çox olmamalıdır',
      'more-than': 'Tarix aralığı %d gündən az olmamalıdır',
      'default-more': '%d gündən çox bir tarix seçin',
      'default-single': 'Tarix seçin',
      'default-less': '%d gündən az bir tarix seçin',
      'default-range': '%d və %d gün aralığında tarixlər seçin',
      'default-default': 'Tarix aralığı seçin',
    },
    //simplified chinese
    cn: {
      selected: '已选择:',
      day: '天',
      days: '天',
      apply: '确定',
      'week-1': '一',
      'week-2': '二',
      'week-3': '三',
      'week-4': '四',
      'week-5': '五',
      'week-6': '六',
      'week-7': '日',
      'week-number': '周',
      'month-name': [
        '一月',
        '二月',
        '三月',
        '四月',
        '五月',
        '六月',
        '七月',
        '八月',
        '九月',
        '十月',
        '十一月',
        '十二月',
      ],
      shortcuts: '快捷选择',
      past: '过去',
      following: '将来',
      previous: '&nbsp;&nbsp;&nbsp;',
      'prev-week': '上周',
      'prev-month': '上个月',
      'prev-year': '去年',
      next: '&nbsp;&nbsp;&nbsp;',
      'next-week': '下周',
      'next-month': '下个月',
      'next-year': '明年',
      'less-than': '所选日期范围不能大于%d天',
      'more-than': '所选日期范围不能小于%d天',
      'default-more': '请选择大于%d天的日期范围',
      'default-less': '请选择小于%d天的日期范围',
      'default-range': '请选择%d天到%d天的日期范围',
      'default-single': '请选择一个日期',
      'default-default': '请选择一个日期范围',
      time: '时间',
      hour: '小时',
      minute: '分钟',
    },
    cz: {
      selected: 'Vybráno:',
      day: 'Den',
      days: 'Dny',
      apply: 'Zavřít',
      'week-1': 'po',
      'week-2': 'út',
      'week-3': 'st',
      'week-4': 'čt',
      'week-5': 'pá',
      'week-6': 'so',
      'week-7': 'ne',
      'month-name': [
        'leden',
        'únor',
        'březen',
        'duben',
        'květen',
        'červen',
        'červenec',
        'srpen',
        'září',
        'říjen',
        'listopad',
        'prosinec',
      ],
      shortcuts: 'Zkratky',
      past: 'po',
      following: 'následující',
      previous: 'předchozí',
      'prev-week': 'týden',
      'prev-month': 'měsíc',
      'prev-year': 'rok',
      next: 'další',
      'next-week': 'týden',
      'next-month': 'měsíc',
      'next-year': 'rok',
      'less-than': 'Rozsah data by neměl být větší než %d dnů',
      'more-than': 'Rozsah data by neměl být menší než %d dnů',
      'default-more': 'Prosím zvolte rozsah data větší než %d dnů',
      'default-single': 'Prosím zvolte datum',
      'default-less': 'Prosím zvolte rozsah data menší než %d dnů',
      'default-range': 'Prosím zvolte rozsah data mezi %d a %d dny',
      'default-default': 'Prosím zvolte rozsah data',
    },
    de: {
      selected: 'Auswahl:',
      day: 'Tag',
      days: 'Tage',
      apply: 'Schließen',
      'week-1': 'mo',
      'week-2': 'di',
      'week-3': 'mi',
      'week-4': 'do',
      'week-5': 'fr',
      'week-6': 'sa',
      'week-7': 'so',
      'month-name': [
        'januar',
        'februar',
        'märz',
        'april',
        'mai',
        'juni',
        'juli',
        'august',
        'september',
        'oktober',
        'november',
        'dezember',
      ],
      shortcuts: 'Schnellwahl',
      past: 'Vorherige',
      following: 'Folgende',
      previous: 'Vorherige',
      'prev-week': 'Woche',
      'prev-month': 'Monat',
      'prev-year': 'Jahr',
      next: 'Nächste',
      'next-week': 'Woche',
      'next-month': 'Monat',
      'next-year': 'Jahr',
      'less-than': 'Datumsbereich darf nicht größer sein als %d Tage',
      'more-than': 'Datumsbereich darf nicht kleiner sein als %d Tage',
      'default-more': 'Bitte mindestens %d Tage auswählen',
      'default-single': 'Bitte ein Datum auswählen',
      'default-less': 'Bitte weniger als %d Tage auswählen',
      'default-range': 'Bitte einen Datumsbereich zwischen %d und %d Tagen auswählen',
      'default-default': 'Bitte ein Start- und Enddatum auswählen',
      Time: 'Zeit',
      hour: 'Stunde',
      minute: 'Minute',
      entrada: 'Anreisedatum',
      salida: 'Abreisedatum',
      'current-year': 'ausgewähltes Jahr',
      'previous-month': 'letzten Monat',
      'next-month': 'nächsten Monat',
      'selected-day': 'ausgewählter Tag',
    },
    es: {
      selected: 'Seleccionado:',
      day: 'Día',
      days: 'Días',
      apply: 'Cerrar',
      'week-1': 'lu',
      'week-2': 'ma',
      'week-3': 'mi',
      'week-4': 'ju',
      'week-5': 'vi',
      'week-6': 'sa',
      'week-7': 'do',
      'month-name': [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
      ],
      shortcuts: 'Accesos directos',
      past: 'Pasado',
      following: 'Siguiente',
      previous: 'Anterior',
      'prev-week': 'Semana',
      'prev-month': 'Mes',
      'prev-year': 'Año',
      next: 'Siguiente',
      'next-week': 'Semana',
      'next-month': 'Mes',
      'next-year': 'Año',
      'less-than': 'El rango no debería ser mayor de %d días',
      'more-than': 'El rango no debería ser menor de %d días',
      'default-more': 'Por favor selecciona un rango mayor a %d días',
      'default-single': 'Por favor selecciona un día',
      'default-less': 'Por favor selecciona un rango menor a %d días',
      'default-range': 'Por favor selecciona un rango entre %d y %d días',
      'default-default': 'Por favor selecciona un rango de fechas.',
      entrada: 'Fecha de entrada',
      salida: 'Fecha de salida',
      'current-year': 'año actual',
      'previous-month': 'mes anterior',
      'next-month': 'mes siguiente',
      'selected-day': 'día seleccionado',
    },
    fr: {
      selected: 'Sélection:',
      day: 'Jour',
      days: 'Jours',
      apply: 'Fermer',
      'week-1': 'lu',
      'week-2': 'ma',
      'week-3': 'me',
      'week-4': 'je',
      'week-5': 've',
      'week-6': 'sa',
      'week-7': 'di',
      'month-name': [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
      ],
      shortcuts: 'Raccourcis',
      past: 'Passé',
      following: 'Suivant',
      previous: 'Précédent',
      'prev-week': 'Semaine',
      'prev-month': 'Mois',
      'prev-year': 'Année',
      next: 'Suivant',
      'next-week': 'Semaine',
      'next-month': 'Mois',
      'next-year': 'Année',
      'less-than': "L'intervalle ne doit pas être supérieure à %d jours",
      'more-than': "L'intervalle ne doit pas être inférieure à %d jours",
      'default-more': 'Merci de choisir une intervalle supérieure à %d jours',
      'default-single': 'Merci de choisir une date',
      'default-less': 'Merci de choisir une intervalle inférieure %d jours',
      'default-range': 'Merci de choisir une intervalle comprise entre %d et %d jours',
      'default-default': 'Merci de choisir une date',
      entrada: 'Date d’arrivée',
      salida: 'Date de départ',
      'current-year': 'année sélectionnée',
      'previous-month': 'le mois dernier',
      'next-month': 'le mois prochain',
      'selected-day': 'jour sélectionné',
    },
    hu: {
      selected: 'Kiválasztva:',
      day: 'Nap',
      days: 'Nap',
      apply: 'Ok',
      'week-1': 'h',
      'week-2': 'k',
      'week-3': 'sz',
      'week-4': 'cs',
      'week-5': 'p',
      'week-6': 'sz',
      'week-7': 'v',
      'month-name': [
        'január',
        'február',
        'március',
        'április',
        'május',
        'június',
        'július',
        'augusztus',
        'szeptember',
        'október',
        'november',
        'december',
      ],
      shortcuts: 'Gyorsválasztó',
      past: 'Múlt',
      following: 'Következő',
      previous: 'Előző',
      'prev-week': 'Hét',
      'prev-month': 'Hónap',
      'prev-year': 'Év',
      next: 'Következő',
      'next-week': 'Hét',
      'next-month': 'Hónap',
      'next-year': 'Év',
      'less-than': 'A kiválasztás nem lehet több %d napnál',
      'more-than': 'A kiválasztás nem lehet több %d napnál',
      'default-more': 'Válassz ki egy időszakot ami hosszabb mint %d nap',
      'default-single': 'Válassz egy napot',
      'default-less': 'Válassz ki egy időszakot ami rövidebb mint %d nap',
      'default-range': 'Válassz ki egy %d - %d nap hosszú időszakot',
      'default-default': 'Válassz ki egy időszakot',
    },
    it: {
      selected: 'Selezionati:',
      day: 'Giorno',
      days: 'Giorni',
      apply: 'Chiudi',
      'week-1': 'lu',
      'week-2': 'ma',
      'week-3': 'me',
      'week-4': 'gi',
      'week-5': 've',
      'week-6': 'sa',
      'week-7': 'do',
      'month-name': [
        'gennaio',
        'febbraio',
        'marzo',
        'aprile',
        'maggio',
        'giugno',
        'luglio',
        'agosto',
        'settembre',
        'ottobre',
        'novembre',
        'dicembre',
      ],
      shortcuts: 'Scorciatoie',
      past: 'Scorso',
      following: 'Successivo',
      previous: 'Precedente',
      'prev-week': 'Settimana',
      'prev-month': 'Mese',
      'prev-year': 'Anno',
      next: 'Prossimo',
      'next-week': 'Settimana',
      'next-month': 'Mese',
      'next-year': 'Anno',
      'less-than': "L'intervallo non dev'essere maggiore di %d giorni",
      'more-than': "L'intervallo non dev'essere minore di %d giorni",
      'default-more': 'Seleziona un intervallo maggiore di %d giorni',
      'default-single': 'Seleziona una data',
      'default-less': 'Seleziona un intervallo minore di %d giorni',
      'default-range': 'Seleziona un intervallo compreso tra i %d e i %d giorni',
      'default-default': 'Seleziona un intervallo di date',
      entrada: 'Data di arrivo',
      salida: 'Data di check-out',
      'current-year': 'anno selezionato',
      'previous-month': 'lo scorso mese',
      'next-month': 'il prossimo mese',
      'selected-day': 'giorno selezionato',
    },
    no: {
      selected: 'Valgt:',
      day: 'Dag',
      days: 'Dager',
      apply: 'Lukk',
      'week-1': 'ma',
      'week-2': 'ti',
      'week-3': 'on',
      'week-4': 'to',
      'week-5': 'fr',
      'week-6': 'lø',
      'week-7': 'sø',
      'month-name': [
        'januar',
        'februar',
        'mars',
        'april',
        'mai',
        'juni',
        'juli',
        'august',
        'september',
        'oktober',
        'november',
        'desember',
      ],
      shortcuts: 'Snarveier',
      'custom-values': 'Egendefinerte Verdier',
      past: 'Over', // Not quite sure about the context of this one
      following: 'Følger',
      previous: 'Forrige',
      'prev-week': 'Uke',
      'prev-month': 'Måned',
      'prev-year': 'År',
      next: 'Neste',
      'next-week': 'Uke',
      'next-month': 'Måned',
      'next-year': 'År',
      'less-than': 'Datoperioden skal ikkje være lengre enn %d dager',
      'more-than': 'Datoperioden skal ikkje være kortere enn %d dager',
      'default-more': 'Vennligst velg ein datoperiode lengre enn %d dager',
      'default-single': 'Vennligst velg ein dato',
      'default-less': 'Vennligst velg ein datoperiode mindre enn %d dager',
      'default-range': 'Vennligst velg ein datoperiode mellom %d og %d dager',
      'default-default': 'Vennligst velg ein datoperiode',
      time: 'Tid',
      hour: 'Time',
      minute: 'Minutter',
    },
    nl: {
      selected: 'Geselecteerd:',
      day: 'Dag',
      days: 'Dagen',
      apply: 'Ok',
      'week-1': 'ma',
      'week-2': 'di',
      'week-3': 'wo',
      'week-4': 'do',
      'week-5': 'vr',
      'week-6': 'za',
      'week-7': 'zo',
      'month-name': [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'october',
        'november',
        'december',
      ],
      shortcuts: 'Snelkoppelingen',
      'custom-values': 'Aangepaste waarden',
      past: 'Verleden',
      following: 'Komend',
      previous: 'Vorige',
      'prev-week': 'Week',
      'prev-month': 'Maand',
      'prev-year': 'Jaar',
      next: 'Volgende',
      'next-week': 'Week',
      'next-month': 'Maand',
      'next-year': 'Jaar',
      'less-than': 'Interval moet langer dan %d dagen zijn',
      'more-than': 'Interval mag niet minder dan %d dagen zijn',
      'default-more': 'Selecteer een interval langer dan %dagen',
      'default-single': 'Selecteer een datum',
      'default-less': 'Selecteer een interval minder dan %d dagen',
      'default-range': 'Selecteer een interval tussen %d en %d dagen',
      'default-default': 'Selecteer een interval',
      time: 'Tijd',
      hour: 'Uur',
      minute: 'Minuut',
    },
    ru: {
      selected: 'Выбрано:',
      day: 'День',
      days: 'Дней',
      apply: 'Закрыть',
      'week-1': 'пн',
      'week-2': 'вт',
      'week-3': 'ср',
      'week-4': 'чт',
      'week-5': 'пт',
      'week-6': 'сб',
      'week-7': 'вс',
      'month-name': [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь',
      ],
      shortcuts: 'Быстрый выбор',
      past: 'Прошедшие',
      following: 'Следующие',
      previous: '&nbsp;&nbsp;&nbsp;',
      'prev-week': 'Неделя',
      'prev-month': 'Месяц',
      'prev-year': 'Год',
      next: '&nbsp;&nbsp;&nbsp;',
      'next-week': 'Неделя',
      'next-month': 'Месяц',
      'next-year': 'Год',
      'less-than': 'Диапазон не может быть больше %d дней',
      'more-than': 'Диапазон не может быть меньше %d дней',
      'default-more': 'Пожалуйста выберите диапазон больше %d дней',
      'default-single': 'Пожалуйста выберите дату',
      'default-less': 'Пожалуйста выберите диапазон меньше %d дней',
      'default-range': 'Пожалуйста выберите диапазон между %d и %d днями',
      'default-default': 'Пожалуйста выберите диапазон',
      entrada: 'Дата заезда',
      salida: 'Дата выезда',
      'current-year': 'выбранный год',
      'previous-month': 'прошлый месяц',
      'next-month': 'в следующем месяце',
      'selected-day': 'выбранный день',
    },
    pl: {
      selected: 'Wybrany:',
      day: 'Dzień',
      days: 'Dni',
      apply: 'Zamknij',
      'week-1': 'pon',
      'week-2': 'wt',
      'week-3': 'śr',
      'week-4': 'czw',
      'week-5': 'pt',
      'week-6': 'so',
      'week-7': 'nd',
      'month-name': [
        'styczeń',
        'luty',
        'marzec',
        'kwiecień',
        'maj',
        'czerwiec',
        'lipiec',
        'sierpień',
        'wrzesień',
        'październik',
        'listopad',
        'grudzień',
      ],
      shortcuts: 'Skróty',
      'custom-values': 'Niestandardowe wartości',
      past: 'Przeszłe',
      following: 'Następne',
      previous: 'Poprzednie',
      'prev-week': 'tydzień',
      'prev-month': 'miesiąc',
      'prev-year': 'rok',
      next: 'Następny',
      'next-week': 'tydzień',
      'next-month': 'miesiąc',
      'next-year': 'rok',
      'less-than': 'Okres nie powinien być dłuższy niż %d dni',
      'more-than': 'Okres nie powinien być krótszy niż  %d ni',
      'default-more': 'Wybierz okres dłuższy niż %d dni',
      'default-single': 'Wybierz datę',
      'default-less': 'Wybierz okres krótszy niż %d dni',
      'default-range': 'Wybierz okres trwający od %d do %d dni',
      'default-default': 'Wybierz okres',
      time: 'Czas',
      hour: 'Godzina',
      minute: 'Minuta',
    },
    se: {
      selected: 'Vald:',
      day: 'dag',
      days: 'dagar',
      apply: 'godkänn',
      'week-1': 'ma',
      'week-2': 'ti',
      'week-3': 'on',
      'week-4': 'to',
      'week-5': 'fr',
      'week-6': 'lö',
      'week-7': 'sö',
      'month-name': [
        'januari',
        'februari',
        'mars',
        'april',
        'maj',
        'juni',
        'juli',
        'augusti',
        'september',
        'oktober',
        'november',
        'december',
      ],
      shortcuts: 'genvägar',
      'custom-values': 'Anpassade värden',
      past: 'över',
      following: 'följande',
      previous: 'förra',
      'prev-week': 'vecka',
      'prev-month': 'månad',
      'prev-year': 'år',
      next: 'nästa',
      'next-week': 'vecka',
      'next-month': 'måned',
      'next-year': 'år',
      'less-than': 'Datumintervall bör inte vara mindre än %d dagar',
      'more-than': 'Datumintervall bör inte vara mer än %d dagar',
      'default-more': 'Välj ett datumintervall längre än %d dagar',
      'default-single': 'Välj ett datum',
      'default-less': 'Välj ett datumintervall mindre än %d dagar',
      'default-range': 'Välj ett datumintervall mellan %d och %d dagar',
      'default-default': 'Välj ett datumintervall',
      time: 'tid',
      hour: 'timme',
      minute: 'minut',
    },
    //Portuguese (European)
    pt: {
      selected: 'Selecionado:',
      day: 'Dia',
      days: 'Dias',
      apply: 'Fechar',
      'week-1': 'seg',
      'week-2': 'ter',
      'week-3': 'qua',
      'week-4': 'qui',
      'week-5': 'sex',
      'week-6': 'sab',
      'week-7': 'dom',
      'week-number': 'N',
      'month-name': [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
      ],
      shortcuts: 'Atalhos',
      'custom-values': 'Valores Personalizados',
      past: 'Passado',
      following: 'Seguinte',
      previous: 'Anterior',
      'prev-week': 'Semana',
      'prev-month': 'Mês',
      'prev-year': 'Ano',
      next: 'Próximo',
      'next-week': 'Próxima Semana',
      'next-month': 'Próximo Mês',
      'next-year': 'Próximo Ano',
      'less-than': 'O período selecionado não deve ser maior que %d dias',
      'more-than': 'O período selecionado não deve ser menor que %d dias',
      'default-more': 'Selecione um período superior a %d dias',
      'default-single': 'Selecione uma data',
      'default-less': 'Selecione um período inferior a %d dias',
      'default-range': 'Selecione um período de %d a %d dias',
      'default-default': 'Selecione um período',
      time: 'Tempo',
      hour: 'Hora',
      minute: 'Minuto',
      entrada: 'Data do check-in',
      salida: 'Data do check-out',
      'current-year': 'ano selecionado',
      'previous-month': 'mês anterior',
      'next-month': 'próximo mês',
      'selected-day': 'dia selecionado',
    },
  }

  $.fn.dateRangePicker = function (opt) {
    if (!opt) opt = {}
    opt = $.extend(
      true,
      {
        autoClose: false,
        format: 'YYYY-MM-DD',
        separator: ' to ',
        language: 'auto',
        startOfWeek: 'sunday', // or monday
        getValue() {
          return $(this).val()
        },
        setValue(s) {
          if (!$(this).attr('readonly') && !$(this).is(':disabled') && s != $(this).val()) {
            $(this).val(s)
          }
        },
        startDate: false,
        endDate: false,
        time: {
          enabled: false,
        },
        minDays: 0,
        maxDays: 0,
        showShortcuts: false,
        shortcuts: {
          //'prev-days': [1,3,5,7],
          // 'next-days': [3,5,7],
          //'prev' : ['week','month','year'],
          // 'next' : ['week','month','year']
        },
        customShortcuts: [],
        inline: false,
        container: 'body',
        alwaysOpen: false,
        singleDate: false,
        lookBehind: false,
        batchMode: false,
        duration: 200,
        stickyMonths: false,
        dayDivAttrs: [],
        dayTdAttrs: [],
        selectForward: false,
        selectBackward: false,
        applyBtnClass: '',
        singleMonth: 'auto',
        isHorizon: document.querySelector('.js-b-login-registration-lightbox'),
        hoveringTooltip(days, startTime, hoveringTime) {
          return days > 1 ? `${days} ${lang('days')}` : ''
        },
        showTopbar: true,
        swapTime: false,
        showWeekNumbers: false,
        getWeekNumber(date) {
          //date will be the first day of a week
          return dayjs(date).format('w')
        },
        customOpenAnimation: null,
        customCloseAnimation: null,
        offerLegend: false,
        offerLegendText: '',
        selectedDatesLegendText: '',
        positionRelativeToDocument: false,
        preventCloseFn(days) {
          // This option allows prevent datepicker auto close if returns true
          // Returns false by default to allow datepicker auto close
          return false
        },
      },
      opt
    )

    if (opt.language === 'auto') {
      setAsyncDayjsLocale()
    } else {
      setAsyncDayjsLocale(opt.language)
    }

    opt.start = false
    opt.end = false

    opt.startWeek = false

    //detect a touch device
    opt.isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints

    //if it is a touch device, hide hovering tooltip
    if (opt.isTouchDevice) opt.hoveringTooltip = false

    //show one month on mobile devices
    if (opt.singleMonth == 'auto') opt.singleMonth = $(window).width() < 480
    if (opt.singleMonth) opt.stickyMonths = false

    if (!opt.showTopbar && opt.autoClose) opt.autoClose = true

    if (opt.startDate && typeof opt.startDate === 'string') opt.startDate = dayjs(opt.startDate, opt.format).toDate()
    if (opt.endDate && typeof opt.endDate === 'string') opt.endDate = dayjs(opt.endDate, opt.format).toDate()

    var langs = getLanguages()
    var box
    var initiated = false
    var self = this
    var selfDom = $(self).get(0)
    var domChangeTimer

    $(this)
      .unbind('.datepicker')
      .bind('click.datepicker', function (evt) {
        var isOpen = false
        if (!isOpen) open(opt.duration)
      })
      .bind('change.datepicker', function (evt) {
        checkAndSetDefaultValue()
      })
      .bind('keyup.datepicker', function () {
        try {
          clearTimeout(domChangeTimer)
        } catch (e) {}
        domChangeTimer = setTimeout(function () {
          checkAndSetDefaultValue()
        }, 2000)
      })

    init_datepicker.call(this)

    if (opt.alwaysOpen) {
      open(0)
    }

    // expose some api
    $(this).data('dateRangePicker', {
      setDateRange(d1, d2, silent) {
        if (typeof d1 === 'string' && typeof d2 === 'string') {
          d1 = dayjs(d1, opt.format).toDate()
          d2 = dayjs(d2, opt.format).toDate()
        }
        setDateRange(d1, d2, silent)
      },
      clear: clearSelection,
      close: closeDatePicker,
      open,
      redraw: redrawDatePicker,
      getDatePicker,
      updateFirstDate,
      setLastDate,
      updateLastDate,
      resetMonthsView,
      showMonth,
      nextMonth,
      calcPosition,
      opt,
      destroy() {
        $(self).unbind('.datepicker')
        $(self).data('dateRangePicker', '')
        $(self).data('date-picker-opened', null)
        box.remove()
        $(window).unbind('resize.datepicker', calcPosition)
        $(document).unbind('click.datepicker', closeDatePicker)
      },
    })

    if (IB.currentDevice !== 'tablet' && IB.currentDevice !== 'mobile')
      $(window).bind('resize.datepicker', calcPosition)

    return this

    function IsOwnDatePickerClicked(evt, selfObj) {
      return (
        selfObj.contains(evt.target) ||
        evt.target == selfObj ||
        (selfObj.childNodes != undefined && $.inArray(evt.target, selfObj.childNodes) >= 0)
      )
    }

    function init_datepicker() {
      var self = this

      if ($(this).data('date-picker-opened')) {
        closeDatePicker()
        return
      }
      $(this).data('date-picker-opened', true)

      box = createDom().hide()
      window.IB.rangedatepickerBox = box
      box.append('<div class="date-range-length-tip hide-on-mobile"></div>')
      box.delegate('.day', 'mouseleave', function () {
        box.find('.date-range-length-tip').hide()
        if (opt.singleDate) {
          clearHovering()
        }
      })

      $(opt.container).append(box)

      if (!opt.inline) {
        calcPosition()
      } else if (opt.inline_mobile_panel) {
        opt.appendTimer = {}
        box
          .addClass('inline-wrapper mobile-panel mobile-fastbooking-cnt top opened-picker')
          .attr('data-mobile-panel', 'true')
        box.mobilepanel()
      } else {
        box.addClass('inline-wrapper')
      }

      if (opt.alwaysOpen) {
        box.find('.apply-btn').hide()
      }

      var defaultTime = getDefaultTime()
      resetMonthsView(defaultTime, opt)

      if (opt.time.enabled) {
        if ((opt.startDate && opt.endDate) || (opt.start && opt.end)) {
          showTime(dayjs(opt.start || opt.startDate).toDate(), 'time1')
          showTime(dayjs(opt.end || opt.endDate).toDate(), 'time2')
        } else {
          var defaultEndTime = opt.defaultEndTime ? opt.defaultEndTime : defaultTime
          showTime(defaultTime, 'time1')
          showTime(defaultEndTime, 'time2')
        }
      }

      //showSelectedInfo();

      var defaultTopText = ''
      if (opt.singleDate) defaultTopText = lang('default-single')
      else if (opt.minDays && opt.maxDays) defaultTopText = lang('default-range')
      else if (opt.minDays) defaultTopText = lang('default-more')
      else if (opt.maxDays) defaultTopText = lang('default-less')
      else defaultTopText = lang('default-default')

      box.find('.default-top').html(defaultTopText.replace(/\%d/, opt.minDays).replace(/\%d/, opt.maxDays))
      if (opt.singleMonth) {
        box.addClass('single-month')
      } else {
        box.addClass('two-months')
      }

      setTimeout(function () {
        updateCalendarWidth()
        initiated = true
      }, 0)

      box.click(function (evt) {
        evt.stopPropagation()
      })

      //if user click other place of the webpage, close date range picker window
      $(document).bind('click.datepicker', function (evt) {
        if (!IsOwnDatePickerClicked(evt, self[0])) {
          if (box.is(':visible')) closeDatePicker()
        }
      })

      box.find('.next').click(function () {
        if (!opt.stickyMonths) {
          gotoNextMonth(this)
        } else {
          gotoNextMonth_stickily(this)
        }
      })

      function gotoNextMonth(self) {
        var isMonth2 = $(self).parents('table').hasClass('month2')
        var month = isMonth2 ? opt.month2 : opt.month1
        month = nextMonth(month)
        if (
          (!opt.singleMonth && !opt.singleDate && !isMonth2 && compare_month(month, opt.month2) >= 0) ||
          isMonthOutOfBounds(month)
        )
          return
        showMonth(month, isMonth2 ? 'month2' : 'month1', opt)
        showGap()
      }

      function gotoNextMonth_stickily(self) {
        var nextMonth1 = nextMonth(opt.month1)
        var nextMonth2 = nextMonth(opt.month2)
        if (isMonthOutOfBounds(nextMonth2)) return
        if (!opt.singleDate && compare_month(nextMonth1, nextMonth2) >= 0) return
        showMonth(nextMonth1, 'month1', opt)
        showMonth(nextMonth2, 'month2', opt)
        showSelectedDays()
      }

      box.find('.prev').click(function () {
        if (!opt.stickyMonths) gotoPrevMonth(this)
        else gotoPrevMonth_stickily(this)
      })

      function gotoPrevMonth(self) {
        var isMonth2 = $(self).parents('table').hasClass('month2')
        var month = isMonth2 ? opt.month2 : opt.month1
        month = prevMonth(month)
        if ((isMonth2 && compare_month(month, opt.month1) <= 0) || isMonthOutOfBounds(month)) return
        showMonth(month, isMonth2 ? 'month2' : 'month1', opt)
        showGap()
      }

      function gotoPrevMonth_stickily(self) {
        var prevMonth1 = prevMonth(opt.month1)
        var prevMonth2 = prevMonth(opt.month2)
        if (isMonthOutOfBounds(prevMonth1)) return
        if (!opt.singleDate && compare_month(prevMonth2, prevMonth1) <= 0) return
        showMonth(prevMonth2, 'month2', opt)
        showMonth(prevMonth1, 'month1', opt)
        showSelectedDays()
      }

      box.delegate('.day', 'click', function (evt) {
        dayClicked($(this))
      })

      box.delegate('.day', 'mouseenter', function (evt) {
        dayHovering($(this))
      })

      box.delegate('.week-number', 'click', function (evt) {
        weekNumberClicked($(this))
      })

      box
        .attr('unselectable', 'on')
        .css('user-select', 'none')
        .bind('selectstart', function (e) {
          e.preventDefault()
          return false
        })

      box.find('.apply-btn, .return-btn').click(function () {
        closeDatePicker()
        var dateRange = getDateString(new Date(opt.start)) + opt.separator + getDateString(new Date(opt.end))
        $(self).trigger('datepicker-apply', {
          value: dateRange,
          date1: new Date(opt.start),
          date2: new Date(opt.end),
        })

        if ($(this).hasClass('apply-btn')) {
          $('.mobile-fastbooking-bg').remove()
          $('.mobile-panel').removeClass('opened')

          $('html').removeClass('mobile-panel-opened')
          ScrollLock.off()
        }
      })

      box.find('[custom]').click(function () {
        var valueName = $(this).attr('custom')
        opt.start = false
        opt.end = false
        box.find('.day.checked').removeClass('checked').removeAttr('aria-label')
        opt.setValue.call(selfDom, valueName)
        checkSelectionValid()
        showSelectedInfo(true)
        showSelectedDays()
        if (opt.autoClose) closeDatePicker()
      })

      box.find('[shortcut]').click(function () {
        var shortcut = $(this).attr('shortcut')
        var end = new Date(),
          start = false
        if (shortcut.indexOf('day') != -1) {
          var day = parseInt(shortcut.split(',', 2)[1], 10)
          start = new Date(new Date().getTime() + 86400000 * day)
          end = new Date(end.getTime() + 86400000 * (day > 0 ? 1 : -1))
        } else if (shortcut.indexOf('week') != -1) {
          var dir = shortcut.indexOf('prev,') != -1 ? -1 : 1

          if (dir == 1) var stopDay = opt.startOfWeek == 'monday' ? 1 : 0
          else var stopDay = opt.startOfWeek == 'monday' ? 0 : 6

          end = new Date(end.getTime() - 86400000)
          while (end.getDay() != stopDay) end = new Date(end.getTime() + dir * 86400000)
          start = new Date(end.getTime() + dir * 86400000 * 6)
        } else if (shortcut.indexOf('month') != -1) {
          var dir = shortcut.indexOf('prev,') != -1 ? -1 : 1
          if (dir == 1) start = nextMonth(end)
          else start = prevMonth(end)
          start.setDate(1)
          end = nextMonth(start)
          end.setDate(1)
          end = new Date(end.getTime() - 86400000)
        } else if (shortcut.indexOf('year') != -1) {
          var dir = shortcut.indexOf('prev,') != -1 ? -1 : 1
          start = new Date()
          start.setFullYear(end.getFullYear() + dir)
          start.setMonth(0)
          start.setDate(1)
          end.setFullYear(end.getFullYear() + dir)
          end.setMonth(11)
          end.setDate(31)
        } else if (shortcut == 'custom') {
          var name = $(this).html()
          if (opt.customShortcuts && opt.customShortcuts.length > 0) {
            for (var i = 0; i < opt.customShortcuts.length; i++) {
              var sh = opt.customShortcuts[i]
              if (sh.name == name) {
                var data = []
                // try
                // {
                data = sh['dates'].call()
                //}catch(e){}
                if (data && data.length == 2) {
                  start = data[0]
                  end = data[1]
                }

                // if only one date is specified then just move calendars there
                // move calendars to show this date's month and next months
                if (data && data.length == 1) {
                  movetodate = data[0]
                  showMonth(movetodate, 'month1', opt)
                  showMonth(nextMonth(movetodate), 'month2', opt)
                  showGap()
                }

                break
              }
            }
          }
        }
        if (start && end) {
          setDateRange(start, end)
          checkSelectionValid()
        }
      })

      box.find('.time1 input[type=range]').bind('change touchmove mousemove', function (e) {
        var target = e.target,
          hour =
            target.name == 'hour'
              ? $(target)
                  .val()
                  .replace(/^(\d{1})$/, '0$1')
              : undefined,
          min =
            target.name == 'minute'
              ? $(target)
                  .val()
                  .replace(/^(\d{1})$/, '0$1')
              : undefined
        setTime('time1', hour, min)
      })

      box.find('.time2 input[type=range]').bind('change touchmove mousemove', function (e) {
        var target = e.target,
          hour =
            target.name == 'hour'
              ? $(target)
                  .val()
                  .replace(/^(\d{1})$/, '0$1')
              : undefined,
          min =
            target.name == 'minute'
              ? $(target)
                  .val()
                  .replace(/^(\d{1})$/, '0$1')
              : undefined
        setTime('time2', hour, min)
      })

      box.delegate('.year-selector', 'change', function (evt) {
        var currentYear = dayjs().year()
        var currentMonth = dayjs().month() + 1
        var selectedYear = parseInt($(this).val())
        var selectedMonth = 1 // By default, show first month
        if (currentYear === selectedYear) {
          // If current year is selected, show current month
          selectedMonth = currentMonth
        }
        selectedMonth = `${selectedMonth}`.padStart(2, 0)

        var year = `${selectedYear}-${selectedMonth}-01`
        showMonth(year, 'month1', opt)
        showMonth(nextMonth(year), 'month2', opt)
      })
      var pStart = { x: 0, y: 0 }
      var pStop = { x: 0, y: 0 }
      var baseMonth = 1

      function swipeStart(e) {
        if (!box.is(':visible')) return

        if (typeof e['targetTouches'] !== 'undefined') {
          var touch = e.targetTouches[0]
          pStart.x = touch.screenX
          pStart.y = touch.screenY
        } else {
          pStart.x = e.screenX
          pStart.y = e.screenY
        }
      }

      function swipeEnd(e) {
        if (!box.is(':visible')) return

        if (typeof e['changedTouches'] !== 'undefined') {
          var touch = e.changedTouches[0]
          pStop.x = touch.screenX
          pStop.y = touch.screenY
        } else {
          pStop.x = e.screenX
          pStop.y = e.screenY
        }

        swipeCheck()
      }

      function swipeCheck() {
        var changeY = pStart.y - pStop.y
        var changeX = pStart.x - pStop.x
        if (isPullDown(changeY, changeX) && !isMonthOutOfBounds(prevMonth(opt[`month${baseMonth}`]))) {
          addMonthPrevMobile(baseMonth)
          baseMonth = baseMonth - 1
        }
      }

      function isPullDown(dY, dX) {
        // methods of checking slope, length, direction of line created by swipe action
        return (
          dY < 0 && ((Math.abs(dX) <= 100 && Math.abs(dY) >= 125) || (Math.abs(dX) / Math.abs(dY) <= 0.3 && dY >= 60))
        )
      }

      document.addEventListener(
        'touchstart',
        function (e) {
          swipeStart(e)
        },
        false
      )
      document.addEventListener(
        'touchend',
        function (e) {
          swipeEnd(e)
        },
        false
      )

      box.bind('scroll', function (e) {
        const endOffset = 100
        window.clearTimeout(opt.appendTimer)
        opt.appendTimer = window.setTimeout(() => {
          // Add new month only when scroll is near to reach end of container
          if (box[0].offsetHeight + box[0].scrollTop >= box[0].scrollHeight - endOffset) {
            addMonth()
          }
        }, 30)
      })
    }

    function calcPosition() {
      const isFastbooking = Boolean($(self).closest('.fastbooking').length)
      if (!opt.inline) {
        var offset = $(self).offset()
        const offsetLeft = opt.positionRelativeToDocument ? $(self).offset().left : $(self).get(0).offsetLeft
        const addDocumentScroll = opt.positionRelativeToDocument ? $(document).scrollTop() : 0
        if ($(opt.container).css('position') === 'relative') {
          var containerOffset = $(opt.container).offset()
          box.css({
            top: offset.top - containerOffset.top + $(self).outerHeight() + 4 - addDocumentScroll,
            left: isFastbooking ? $('.fastbooking').find('.container').offset() : offsetLeft,
          })
        } else if (
          document.documentElement.dataset.inMicrosite === 'star_agents' &&
          document.documentElement.dataset.deviceType === 'mobile'
        ) {
          // If box is undefined, obtain box instance from global variable
          !box && (box = window.IB.rangedatepickerBox)
          const sta_modal_height = $('.sta-modal-form').height()
          const top = offset.top + parseInt($('body').css('border-top') || 0, 10) - addDocumentScroll
          box.css({
            top: top + 330 > sta_modal_height ? sta_modal_height - 330 : top,
            left: offsetLeft,
          })
        } else {
          // If box is undefined, obtain box instance from global variable
          !box && (box = window.IB.rangedatepickerBox)
          box.css({
            top:
              offset.top + $(self).outerHeight() + parseInt($('body').css('border-top') || 0, 10) - addDocumentScroll,
            left: isFastbooking ? $('.fastbooking').find('.container').offset() : offsetLeft,
          })
        }
      }
    }

    // Return the date picker wrapper element
    function getDatePicker() {
      return box
    }

    function open(animationTime) {
      calcPosition()
      if (!opt.inline) {
        redrawDatePicker()
      }

      if (opt.inline_mobile_panel) {
        redrawDatePicker()
      }

      checkAndSetDefaultValue()
      if (opt.customOpenAnimation) {
        opt.customOpenAnimation.call(box.get(0), function () {
          $(self).trigger('datepicker-opened', { relatedTarget: box })
        })
      } else {
        box.slideDown(animationTime, function () {
          $(self).trigger('datepicker-opened', { relatedTarget: box })
        })
      }

      createMonthsForScrollToSelectedDateIfExists()
      $(self).trigger('datepicker-open', { relatedTarget: box })
      showGap()
      updateCalendarWidth()
    }

    function createMonthsForScrollToSelectedDateIfExists() {
      if (IB.currentDevice === 'mobile') {
        if ($('.dates-container').find('.check-in').val().length) {
          const date_format = $('html').attr('data-date-format')
          const start_date = new dayjs().format(date_format)
          const selected_date = $('.dates-container').find('.check-in').val()
          const number_of_months = dayjs(selected_date, date_format).diff(dayjs(start_date, date_format), 'months')
          for (var i = 0; i <= number_of_months; i++) {
            addMonth()
          }
        }
      }
    }

    function checkAndSetDefaultValue() {
      var __default_string = opt.getValue.call(selfDom)
      var defaults = __default_string ? __default_string.split(opt.separator) : ''

      if (defaults && ((defaults.length == 1 && opt.singleDate) || defaults.length >= 2)) {
        var ___format = opt.format
        if (___format.match(/Do/)) {
          ___format = ___format.replace(/Do/, 'D')
          defaults[0] = defaults[0].replace(/(\d+)(th|nd|st)/, '$1')
          if (defaults.length >= 2) {
            defaults[1] = defaults[1].replace(/(\d+)(th|nd|st)/, '$1')
          }
        }
        // set initiated  to avoid triggerring datepicker-change event
        initiated = false
        if (defaults.length >= 2) {
          setDateRange(
            getValidValue(defaults[0], ___format, dayjs.locale(opt.language)),
            getValidValue(defaults[1], ___format, dayjs.locale(opt.language))
          )
        } else if (defaults.length == 1 && opt.singleDate) {
          setSingleDate(getValidValue(defaults[0], ___format, dayjs.locale(opt.language)))
        }

        initiated = true
      }
    }

    function getValidValue(date, format, locale) {
      if (dayjs(date, format, locale).isValid()) {
        return dayjs(date, format, locale).toDate()
      }
      return dayjs().toDate()
    }

    function updateCalendarWidth() {
      var month1Margin = box.find('.month1').css('margin-left')
      month1Margin = month1Margin ? parseInt(month1Margin) : 0

      var gapMargin = box.find('.gap').css('margin-left')
      gapMargin = gapMargin ? parseInt(gapMargin) : 0

      var month2Margin = box.find('.month2').css('margin-left')
      month2Margin = month2Margin ? parseInt(month2Margin) : 0

      var w1 = box.find('.month1').width() + month2Margin * 2
      var w2 = box.find('.gap').width() + gapMargin * 2
      var w3 = box.find('.month2').width() + month1Margin * 2

      if (IB.currentDevice === 'mobile') box.find('.month-wrapper').width(w1 + w2 + w3)
    }

    function renderTime(name, date) {
      box.find(`.${name} input[type=range].hour-range`).val(dayjs(date).hours())
      box.find(`.${name} input[type=range].minute-range`).val(dayjs(date).minutes())
      setTime(name, dayjs(date).format('HH'), dayjs(date).format('mm'))
    }

    function changeTime(name, date) {
      opt[name] = parseInt(
        dayjs(parseInt(date))
          .startOf('day')
          .add(dayjs(opt[`${name}Time`]).format('HH'), 'h')
          .add(dayjs(opt[`${name}Time`]).format('mm'), 'm')
          .valueOf()
      )
    }

    function swapTime() {
      renderTime('time1', opt.start)
      renderTime('time2', opt.end)
    }

    function setTime(name, hour, minute) {
      hour && box.find(`.${name} .hour-val`).text(hour)
      minute && box.find(`.${name} .minute-val`).text(minute)
      switch (name) {
        case 'time1':
          if (opt.start) {
            setRange('start', dayjs(opt.start))
          }
          setRange('startTime', dayjs(opt.startTime || dayjs().valueOf()))
          break
        case 'time2':
          if (opt.end) {
            setRange('end', dayjs(opt.end))
          }
          setRange('endTime', dayjs(opt.endTime || dayjs().valueOf()))
          break
      }
      function setRange(name, timePoint) {
        var h = timePoint.format('HH'),
          m = timePoint.format('mm')
        opt[name] = timePoint
          .startOf('day')
          .add(hour || h, 'h')
          .add(minute || m, 'm')
          .valueOf()
      }
      checkSelectionValid()
      showSelectedInfo()
      showSelectedDays()
    }

    function clearSelection() {
      opt.start = false
      opt.end = false
      box.find('.day.checked').removeClass('checked').removeAttr('aria-label')
      box.find('.day.last-date-selected').removeClass('last-date-selected')
      box.find('.day.first-date-selected').removeClass('first-date-selected')
      opt.setValue.call(selfDom, '')
      // updateSelectableRange();
      checkSelectionValid()
      showSelectedInfo()
      showSelectedDays()
    }

    function handleStart(time) {
      var r = time
      if (opt.batchMode === 'week-range') {
        if (opt.startOfWeek === 'monday') {
          r = dayjs(parseInt(time)).startOf('isoweek').valueOf()
        } else {
          r = dayjs(parseInt(time)).startOf('week').valueOf()
        }
      } else if (opt.batchMode === 'month-range') {
        r = dayjs(parseInt(time)).startOf('month').valueOf()
      }
      return r
    }

    function handleEnd(time) {
      var r = time
      if (opt.batchMode === 'week-range') {
        if (opt.startOfWeek === 'monday') {
          r = dayjs(parseInt(time)).endOf('isoweek').valueOf()
        } else {
          r = dayjs(parseInt(time)).endOf('week').valueOf()
        }
      } else if (opt.batchMode === 'month-range') {
        r = dayjs(parseInt(time)).endOf('month').valueOf()
      }
      return r
    }

    function dayClicked(day) {
      if (day.hasClass('invalid')) return
      var time = day.attr('time')
      day.addClass('checked').attr('aria-label', lang('selected-day'))
      if (opt.singleDate) {
        opt.start = time
        opt.end = false
      } else if (opt.batchMode === 'week') {
        if (opt.startOfWeek === 'monday') {
          opt.start = dayjs(parseInt(time)).startOf('isoweek').valueOf()
          opt.end = dayjs(parseInt(time)).endOf('isoweek').valueOf()
        } else {
          opt.end = dayjs(parseInt(time)).endOf('week').valueOf()
          opt.start = dayjs(parseInt(time)).startOf('week').valueOf()
        }
      } else if (opt.batchMode === 'workweek') {
        opt.start = dayjs(parseInt(time)).day(1).valueOf()
        opt.end = dayjs(parseInt(time)).day(5).valueOf()
      } else if (opt.batchMode === 'weekend') {
        opt.start = dayjs(parseInt(time)).day(6).valueOf()
        opt.end = dayjs(parseInt(time)).day(7).valueOf()
      } else if (opt.batchMode === 'month') {
        opt.start = dayjs(parseInt(time)).startOf('month').valueOf()
        opt.end = dayjs(parseInt(time)).endOf('month').valueOf()
      } else if ((opt.start && opt.end) || (!opt.start && !opt.end)) {
        opt.start = handleStart(time)
        opt.end = false
      } else if (opt.start) {
        opt.end = handleEnd(time)
        if (opt.time.enabled) {
          changeTime('end', opt.end)
        }
      }

      //Update time in case it is enabled and timestamps are available
      if (opt.time.enabled) {
        if (opt.start) {
          changeTime('start', opt.start)
        }
        if (opt.end) {
          changeTime('end', opt.end)
        }
      }

      //In case the start is after the end, swap the timestamps
      if (!opt.singleDate && opt.start && opt.end && opt.start > opt.end) {
        var tmp = opt.end
        opt.end = handleEnd(opt.start)
        opt.start = handleStart(tmp)
        if (opt.time.enabled && opt.swapTime) {
          swapTime()
        }
      }

      opt.start = parseInt(opt.start)
      opt.end = parseInt(opt.end)

      clearHovering()
      if (opt.start && !opt.end) {
        $(self).trigger('datepicker-first-date-selected', {
          date1: new Date(opt.start),
          time: opt.start,
        })
        dayHovering(day)
      }
      updateSelectableRange(time)
      checkSelectionValid()
      showSelectedInfo()
      showSelectedDays()
      if (opt.autoClose) autoclose()
    }

    function weekNumberClicked(weekNumberDom) {
      var thisTime = parseInt(weekNumberDom.attr('data-start-time'), 10)
      if (!opt.startWeek) {
        opt.startWeek = thisTime
        weekNumberDom.addClass('week-number-selected')
        var date1 = new Date(thisTime)
        opt.start = dayjs(date1)
          .day(opt.startOfWeek == 'monday' ? 1 : 0)
          .valueOf()
        opt.end = dayjs(date1)
          .day(opt.startOfWeek == 'monday' ? 7 : 6)
          .valueOf()
      } else {
        box.find('.week-number-selected').removeClass('week-number-selected')
        var date1 = new Date(thisTime < opt.startWeek ? thisTime : opt.startWeek)
        var date2 = new Date(thisTime < opt.startWeek ? opt.startWeek : thisTime)
        opt.startWeek = false
        opt.start = dayjs(date1)
          .day(opt.startOfWeek == 'monday' ? 1 : 0)
          .valueOf()
        opt.end = dayjs(date2)
          .day(opt.startOfWeek == 'monday' ? 7 : 6)
          .valueOf()
      }
      updateSelectableRange()
      checkSelectionValid()
      showSelectedInfo()
      showSelectedDays()
      if (opt.autoClose) autoclose()
    }

    function isValidTime(time) {
      time = parseInt(time, 10)
      if (opt.startDate && compare_day(time, opt.startDate) < 0) return false
      if (opt.endDate && compare_day(time, opt.endDate) > 0) return false

      if (opt.start && !opt.end && !opt.singleDate) {
        //check maxDays and minDays setting
        const daysFromStart = countDays(time, opt.start)
        if (opt.maxDays > 0 && daysFromStart > opt.maxDays) return false
        if (opt.minDays > 0 && daysFromStart < opt.minDays) return false

        //check selectForward and selectBackward
        if (opt.selectForward && time < opt.start) return false
        if (opt.selectBackward && time > opt.start) return false

        //check disabled days
        if (opt.beforeShowDay) {
          const dayOpts = opt.beforeShowDay(new Date(time))
          const shouldValidate = !dayOpts[3]
          if (typeof opt.beforeShowDay === 'function' && shouldValidate) {
            var valid = true
            var timeTmp = time
            let daysToCheck = daysFromStart
            while (daysToCheck > 1) {
              var arr = opt.beforeShowDay(new Date(timeTmp))
              if (!arr[0]) {
                valid = false
                break
              }
              if (Math.abs(timeTmp - opt.start) < 86400000) break
              if (timeTmp > opt.start) {
                timeTmp -= 86400000
              } else if (timeTmp < opt.start) {
                timeTmp += 86400000
              }
              daysToCheck -= 1
            }
            if (!valid) return false
          }
        }
      }
      return true
    }

    function updateSelectableRange() {
      box.find('.day.invalid.available').removeClass('tmp invalid').addClass('valid')
      if (opt.start && !opt.end) {
        box.find('.day.toMonth.valid').each(function () {
          var time = parseInt($(this).attr('time'), 10)

          if (!isValidTime(time)) $(this).addClass('invalid tmp').removeClass('valid')
          else $(this).addClass('valid tmp').removeClass('invalid')
        })
      }

      return true
    }

    function updateFirstDate() {
      var real_today
      opt.start = false
      opt.end = false
      if (box.find('.day.real-today').length) {
        real_today = parseInt(box.find('.day.real-today').attr('time'))
      } else {
        real_today = box.find('.day').first().attr('time')
      }
      box.find('.day').each(function () {
        var time = parseInt($(this).attr('time'))
        if (time >= real_today) {
          $(this).removeClass('invalid').addClass('valid')
        }
      })
    }

    function setLastDate(last_date) {
      var real_today
      if (box.find('.day.real-today').length) {
        real_today = parseInt(box.find('.day.real-today').attr('time'))
      } else {
        real_today = box.find('.day').first().attr('time')
      }
      opt.end = parseInt(last_date)
      box.find('.day').each(function () {
        var time = parseInt($(this).attr('time'))
        if (time >= real_today) {
          $(this).removeClass('invalid').addClass('valid')
        }
      })
      updateSelectableRange(opt.end)
      checkSelectionValid()
      showSelectedInfo()
      showSelectedDays()
    }

    function updateLastDate(first_date) {
      if (!opt.start && !opt.end) {
        checkAndSetDefaultValue()
      }

      if (opt.end) {
        var last_day = opt.end
        first_date = undefined
        var $day = $(`.day[data-id=${first_date}]`)
        opt.end = false
        dayClicked($day)
        setTimeout(function () {
          box.find('.day').each(function () {
            var time = parseInt($(this).attr('time'))
            if (time < last_day) {
              $(this).addClass('hovering-permanent')
            } else if (time === last_day) {
              $(this).addClass('last-date-selected')
            }
          })
        }, 0)
      } else if (!opt.end) {
        var $day = $(`.day[data-id=${first_date}]`)
        dayClicked($day)
      }
    }

    function dayHovering(day) {
      var hoverTime = parseInt(day.attr('time'))
      var tooltip = ''
      var tooptipTwoRows = false

      if (day.hasClass('has-tooltip') && day.attr('data-tooltip')) {
        tooltip = `<span style="white-space:nowrap">${day.attr('data-tooltip')}</span>`
      } else if (!day.hasClass('invalid')) {
        if (opt.singleDate) {
          box.find('.day.hovering').removeClass('hovering')
          day.addClass('hovering')
        } else {
          box.find('.day').each(function () {
            var time = parseInt($(this).attr('time')),
              start = opt.start,
              end = opt.end

            if (time == hoverTime) {
              $(this).addClass('hovering')
            } else {
              $(this).removeClass('hovering')
            }

            if (
              opt.start &&
              !opt.end &&
              ((opt.start < time && hoverTime >= time) || (opt.start > time && hoverTime <= time))
            ) {
              $(this).addClass('hovering')
            } else {
              $(this).removeClass('hovering')
            }
          })

          if (opt.start && !opt.end) {
            $(document).trigger('day-hovering', day)
            var days = countDays(hoverTime, opt.start)
            if (opt.hoveringTooltip) {
              let nights = ''
              if (typeof opt.hoveringTooltip === 'function') {
                nights = opt.hoveringTooltip(days, opt.start, hoverTime)
              } else if (opt.hoveringTooltip === true && days > 1) {
                nights = `${days} ${lang('days')}`
              }
              tooptipTwoRows = true
              tooltip = `<span class="tip-title t-link">${lang(
                'salida'
              )}</span><span class="tip-content t-fb-label"><i class="s s-ico_moon"></i>${nights}</span>`
            }
          }

          if (!opt.start) {
            tooltip = `<span class="tip-title t-link">${lang('entrada')}</span>`
          }
        }
      }

      if (tooltip) {
        var posDay = day.offset()
        var posBox = box.offset()
        var _left = tooptipTwoRows ? posDay.left - posBox.left + 18 : posDay.left - posBox.left - 18
        var _top = tooptipTwoRows ? posDay.top - posBox.top - 105 : posDay.top - posBox.top - 88
        _left += day.width() / 2

        var $tip = box.find('.date-range-length-tip')
        var w = $tip.css({ visibility: 'hidden', display: 'none' }).html(tooltip).width()
        var h = $tip.height()
        _left -= w / 2

        if (opt.tooltipPosition === 'top') {
          _top -= h
        } else {
          _top += day.height()
        }

        setTimeout(function () {
          $tip.css({ left: _left, top: _top, display: 'flex', visibility: 'visible' })
        }, 10)
      } else {
        box.find('.date-range-length-tip').hide()
      }
    }

    function clearHovering() {
      box.find('.day.hovering').removeClass('hovering')
      box.find('.date-range-length-tip').hide()
    }

    function autoclose() {
      if (opt.singleDate === true) {
        if (initiated && opt.start) {
          if (opt.autoClose) closeDatePicker()
        }
      } else if (initiated && opt.start && opt.end) {
        if (!opt.preventCloseFn(opt.start, opt.end) && opt.autoClose) closeDatePicker()
      }
    }

    function checkSelectionValid() {
      var days = Math.ceil((opt.end - opt.start) / 86400000) + 1
      if (opt.singleDate) {
        // Validate if only start is there
        if (opt.start && !opt.end) {
          box.find('.drp_top-bar').removeClass('error').addClass('normal')
        } else {
          box.find('.drp_top-bar').removeClass('error').removeClass('normal')
        }
      } else if (opt.maxDays && days > opt.maxDays) {
        opt.start = false
        opt.end = false
        box.find('.day').removeClass('checked').removeAttr('aria-label')
        box
          .find('.drp_top-bar')
          .removeClass('normal')
          .addClass('error')
          .find('.error-top')
          .html(lang('less-than').replace('%d', opt.maxDays))
      } else if (opt.minDays && days < opt.minDays) {
        opt.start = false
        opt.end = false
        box.find('.day').removeClass('checked').removeAttr('aria-label')
        box
          .find('.drp_top-bar')
          .removeClass('normal')
          .addClass('error')
          .find('.error-top')
          .html(lang('more-than').replace('%d', opt.minDays))
      } else if (opt.start || opt.end) {
        box.find('.drp_top-bar').removeClass('error').addClass('normal')
      } else {
        box.find('.drp_top-bar').removeClass('error').removeClass('normal')
      }

      if ((opt.singleDate && opt.start && !opt.end) || (!opt.singleDate && opt.start && opt.end)) {
        box.find('.apply-btn').removeClass('disabled')
      } else {
        box.find('.apply-btn').addClass('disabled')
      }

      if (opt.batchMode) {
        if (
          (opt.start && opt.startDate && compare_day(opt.start, opt.startDate) < 0) ||
          (opt.end && opt.endDate && compare_day(opt.end, opt.endDate) > 0)
        ) {
          opt.start = false
          opt.end = false
          box.find('.day').removeClass('checked').removeAttr('aria-label')
        }
      }
    }

    function showSelectedInfo(forceValid, silent) {
      box.find('.start-day').html('&nbsp;')
      box.find('.end-day').html('&nbsp;')
      if (opt.start) {
        box.find('.start-day').html(getDateString(new Date(parseInt(opt.start))))
      }
      if (opt.end) {
        box.find('.end-day').html(getDateString(new Date(parseInt(opt.end))))
      }

      if (opt.start && opt.singleDate) {
        box.find('.apply-btn').removeClass('disabled')
        var dateRange = getDateString(new Date(opt.start))
        opt.setValue.call(selfDom, dateRange, getDateString(new Date(opt.start)), getDateString(new Date(opt.end)))

        if (initiated && !silent) {
          $(self).trigger('datepicker-change', {
            value: dateRange,
            date1: new Date(opt.start),
          })
        }
      } else if (opt.start && opt.end) {
        box.find('.selected-days').show().find('.selected-days-num').html(countDays(opt.end, opt.start))
        box.find('.apply-btn').removeClass('disabled')
        var dateRange = getDateString(new Date(opt.start)) + opt.separator + getDateString(new Date(opt.end))
        opt.setValue.call(selfDom, dateRange, getDateString(new Date(opt.start)), getDateString(new Date(opt.end)))
        if (initiated && !silent) {
          $(self).trigger('datepicker-change', {
            value: dateRange,
            date1: new Date(opt.start),
            date2: new Date(opt.end),
            opt,
            box,
          })
        }
      } else if (forceValid) {
        box.find('.apply-btn').removeClass('disabled')
      } else {
        box.find('.apply-btn').addClass('disabled')
      }
    }

    function countDays(start, end) {
      return Math.abs(daysFrom1970(start) - daysFrom1970(end)) + 1
    }

    function setDateRange(date1, date2, silent) {
      if (date1.getTime() > date2.getTime()) {
        var tmp = date2
        date2 = date1
        date1 = tmp
        tmp = null
      }
      var valid = true
      if (opt.startDate && compare_day(date1, opt.startDate) < 0) valid = false
      if (opt.endDate && compare_day(date2, opt.endDate) > 0) valid = false
      if (!valid) {
        showMonth(opt.startDate, 'month1', opt)
        showMonth(nextMonth(opt.startDate), 'month2', opt)
        showGap()
        return
      }

      opt.start = date1.getTime()
      opt.end = date2.getTime()

      if (opt.time.enabled) {
        renderTime('time1', date1)
        renderTime('time2', date2)
      }

      if (opt.stickyMonths || (compare_day(date1, date2) > 0 && compare_month(date1, date2) === 0)) {
        if (opt.lookBehind) {
          date1 = prevMonth(date2)
        } else {
          date2 = nextMonth(date1)
        }
      }

      if (opt.stickyMonths && compare_month(date2, opt.endDate) > 0) {
        date1 = prevMonth(date1)
        date2 = prevMonth(date2)
      }

      if (!opt.stickyMonths) {
        if (compare_month(date1, date2) === 0) {
          if (opt.lookBehind) {
            date1 = prevMonth(date2)
          } else {
            date2 = nextMonth(date1)
          }
        }
      }

      if (!opt.inline) {
        showMonth(date1, 'month1', opt)
        showMonth(date2, 'month2', opt)
        showGap()
      }

      checkSelectionValid()
      showSelectedInfo(false, silent)
      if (opt.autoClose) autoclose()
    }

    function setSingleDate(date1) {
      var valid = true
      if (opt.startDate && compare_day(date1, opt.startDate) < 0) valid = false
      if (opt.endDate && compare_day(date1, opt.endDate) > 0) valid = false
      if (!valid) {
        showMonth(opt.startDate, 'month1', opt)
        return
      }

      opt.start = date1.getTime()

      if (opt.time.enabled) {
        renderTime('time1', date1)
      }
      showMonth(date1, 'month1', opt)
      if (opt.singleMonth !== true) {
        date2 = nextMonth(date1)
        showMonth(date2, 'month2', opt)
      }
      showGap()
      showSelectedInfo()
      if (opt.autoClose) autoclose()
    }

    function showSelectedDays() {
      if (!opt.start && !opt.end) return
      box.find('.day').each(function () {
        var time = parseInt($(this).attr('time')),
          start = opt.start,
          end = opt.end
        if (opt.time.enabled) {
          time = dayjs(time).startOf('day').valueOf()
          start = dayjs(start || dayjs().valueOf())
            .startOf('day')
            .valueOf()
          end = dayjs(end || dayjs().valueOf())
            .startOf('day')
            .valueOf()
        }
        if (
          (opt.start && opt.end && end >= time && start <= time) ||
          (opt.start && !opt.end && dayjs(start).format('YYYY-MM-DD') == dayjs(time).format('YYYY-MM-DD'))
        ) {
          $(this).addClass('checked').attr('aria-label', lang('selected-day'))
        } else {
          $(this).removeClass('checked').removeAttr('aria-label')
        }

        //add first-date-selected class name to the first date selected
        if (opt.start && dayjs(start).format('YYYY-MM-DD') == dayjs(time).format('YYYY-MM-DD')) {
          $(this).addClass('first-date-selected').removeClass('invalid')
        } else {
          $(this).removeClass('first-date-selected')
        }
        //add last-date-selected
        if (opt.end && dayjs(end).format('YYYY-MM-DD') == dayjs(time).format('YYYY-MM-DD')) {
          $(this).addClass('last-date-selected')
        } else {
          $(this).removeClass('last-date-selected')
        }
      })

      box.find('.week-number').each(function () {
        if ($(this).attr('data-start-time') == opt.startWeek) {
          $(this).addClass('week-number-selected')
        }
      })
    }

    function showMonth(date, month, opt) {
      var date = dayjs(date).toDate()
      box.find(`.${month} .month-name`).replaceWith(getMonthHead(date, opt))
      box.find(`.${month} tbody`).html(createMonthHTML(date))
      opt[month] = date
      updateSelectableRange()
    }

    function addMonth() {
      var month_qty = box.find('.month').length,
        month = `month${month_qty + 1}`,
        $month_el = $(getInlineMonthWrapper(month)),
        date = dayjs(opt[`month${month_qty}`]).add(1, 'months').toDate()
      $month_el.find('.month-name').replaceWith(getMonthHead(date, { inline: true }))
      $month_el.find('tbody').html(createMonthHTML(date))
      box.find('.month-wrapper').append($month_el)
      opt[month] = date
      updateSelectableRange()
    }

    function addMonthPrevMobile(prevMonth) {
      var month = `month${prevMonth - 1}`,
        $month_el = $(getInlineMonthWrapper(month)),
        date = dayjs(opt[`month${prevMonth}`]).add(-1, 'months').toDate()

      $month_el.find('.month-name').replaceWith(getMonthHead(date, { inline: true }))
      $month_el.find('tbody').html(createMonthHTML(date))
      $month_el.insertBefore(box.find('.month-wrapper').find('.month').first())
      opt[month] = date
      updateSelectableRange()
    }

    function showTime(date, name) {
      box.find(`.${name}`).append(getTimeHTML())
      renderTime(name, date)
    }

    function nameMonth(m) {
      return lang('month-name')[m]
    }

    function getDateString(d) {
      return dayjs(d).format(opt.format)
    }

    function showGap() {
      showSelectedDays()
      var m1 = parseInt(dayjs(opt.month1).format('YYYYMM'))
      var m2 = parseInt(dayjs(opt.month2).format('YYYYMM'))
      var p = Math.abs(m1 - m2)
      var shouldShow = p > 1 && p != 89
      if (shouldShow) {
        box.addClass('has-gap').removeClass('no-gap').find('.gap').css('visibility', 'visible')
      } else {
        box.removeClass('has-gap').addClass('no-gap').find('.gap').css('visibility', 'hidden')
      }
      var h1 = box.find('.month1').height()
      var h2 = box.find('.month2').height()
      box.find('.gap').height(Math.max(h1, h2))
    }

    function closeDatePicker() {
      if (opt.alwaysOpen) return
      var afterAnim = function () {
        $(self).data('date-picker-opened', false)
        $(self).trigger('datepicker-closed', { relatedTarget: box })
      }
      if (opt.customCloseAnimation) {
        opt.customCloseAnimation.call(box.get(0), afterAnim)
      } else {
        $(box).slideUp(opt.duration, afterAnim)
      }
      $(self).trigger('datepicker-close', { relatedTarget: box })
    }

    function redrawDatePicker() {
      if (box === undefined) {
        init_datepicker()
      }
      if (!opt.inline) {
        showMonth(opt.month1, 'month1', opt)
        showMonth(opt.month2, 'month2', opt)
      } else {
        var months_qty = box.find('.month').length
        for (var index = 1; index <= months_qty; index++) {
          var key = `month${index}`
          showMonth(opt[key], key, opt)
        }
      }
      // opt.inline
    }

    function compare_month(m1, m2) {
      var p = parseInt(dayjs(m1).format('YYYYMM')) - parseInt(dayjs(m2).format('YYYYMM'))
      if (p > 0) return 1
      if (p === 0) return 0
      return -1
    }

    function compare_day(m1, m2) {
      var p = parseInt(dayjs(m1).format('YYYYMMDD')) - parseInt(dayjs(m2).format('YYYYMMDD'))
      if (p > 0) return 1
      if (p === 0) return 0
      return -1
    }

    function nextMonth(month) {
      return dayjs(month).add(1, 'months').toDate()
    }

    function prevMonth(month) {
      return dayjs(month).add(-1, 'months').toDate()
    }

    function getTimeHTML() {
      return (
        `<div>` +
        `<span>${lang('Time')}: <span class="hour-val">00</span>:<span class="minute-val">00</span></span>` +
        `</div>` +
        `<div class="hour">` +
        `<label>${lang('Hour')}: <input type="range" class="hour-range" name="hour" min="0" max="23"></label>` +
        `</div>` +
        `<div class="minute">` +
        `<label>${lang('Minute')}: <input type="range" class="minute-range" name="minute" min="0" max="59"></label>` +
        `</div>`
      )
    }

    function createDom() {
      var html = '<div class="date-picker-wrapper'
      if (opt.extraClass) html += ` ${opt.extraClass} `
      if (opt.singleDate) html += ' single-date '
      if (!opt.showShortcuts) html += ' no-shortcuts '
      if (!opt.showTopbar) html += ' no-topbar '
      if (opt.customTopBar) html += ' custom-topbar '
      if (opt.isHorizon) html += 'horizons'
      html += '" aria-modal="true">'

      if (opt.inline_mobile_panel) {
        html += '<div class="dates-info hidden">'
        html += getSelectedDatesLegend(opt)
        html += getOfferLegend(opt)
        html += getMultipleBookingLegend(opt)
        html += '</div>'
      }

      html += "<div class='alert alert-warning package-dates-not-included-warning negative fullWidth hidden'></div>"

      if (opt.showTopbar) {
        html += '<div class="drp_top-bar">'

        if (opt.customTopBar) {
          if (typeof opt.customTopBar === 'function') opt.customTopBar = opt.customTopBar()
          html += `<div class="custom-top">${opt.customTopBar}</div>`
        } else {
          html +=
            '<div class="normal-top dates">' +
            '<div class="date"><span class="label">Check In</span><span class="start-day active">&nbsp;</span></div>'
          if (!opt.singleDate) {
            html += '<div class="date"><span class="label">Check Out</span><span class="end-day">&nbsp;</span></div>'
          }
          html += '</div>'
        }
        html +=
          '<a href="#" class="apply-btn close-mobile-panel" data-close-panel><span class="s s-close-fat"></span></a>'
        html += '</div>'
      }

      if (!opt.inline_mobile_panel) {
        html += '<div class="dates-info hidden">'
        html += getSelectedDatesLegend(opt)
        html += getOfferLegend(opt)
        html += getMultipleBookingLegend(opt)
        html += '</div>'
      }

      var _colspan = opt.showWeekNumbers ? 6 : 5
      if (opt.inline_mobile_panel) {
        html +=
          `<div class="month-wrapper">` +
          // Week names
          `<table class="week-names" cellspacing="0" border="0" cellpadding="0"><thead><tr class="week-name">${getWeekHead()}</tr></thead></table>` +
          `<div class="offer-legend-wrapper">${getOfferLegend(opt)}<span class="nights-label in-date-wrapper"></span>` +
          `</div>${
            // First Month
            getInlineMonthWrapper('month1')
            // Second Month
          }${getInlineMonthWrapper('month2')}${getInlineMonthWrapper('month3')}`
      } else {
        html +=
          `<div class="month-wrapper">` +
          `<div class="month month1 table">` +
          `<div class="thead">` +
          `<div class="caption tr">` +
          `<div class="th" style="width:27px;">` +
          `<button type="button" class="prev" tabindex="0" aria-label="${lang('previous-month')}">&lt;</button>` +
          `</div>` +
          `<div colspan="${_colspan}" class="month-name th"></div>` +
          `<div class="th" style="width:27px;">${
            opt.singleDate || !opt.stickyMonths
              ? `<button type="button" class="next" tabindex="0" aria-label="${lang('next-month')}">&gt;</button>`
              : ''
          }</div>` +
          `</div>` +
          `<table class="table-month" cellspacing="0" border="0" cellpadding="0"><thead><tr class="week-name">${getWeekHead()}</tr></thead><tbody></tbody></table>` +
          `</div>` +
          `</div>`

        if (hasMonth2()) {
          html +=
            `<div class="gap">${getGapHTML()}</div>` +
            `<div class="month month2 table">` +
            `<div class="thead">` +
            `<div class="caption tr">` +
            `<div class="th" style="width:27px;">${
              !opt.stickyMonths ? `<span class="prev" aria-label="${lang('previous-month')}">&lt;</span>` : ''
            }</div>` +
            `<div colspan="${_colspan}" class="month-name th"></div>` +
            `<div class="th" style="width:27px;"><button type="button" class="next" tabindex="0" aria-label="${lang(
              'next-month'
            )}">&gt;</button></div>` +
            `</div>` +
            `<table class="table-month" cellspacing="0" border="0" cellpadding="0"><thead><tr class="week-name">${getWeekHead()}</tr></thead><tbody></tbody></table>` +
            `</div>` +
            `</div>`
        }
      }

      //+'</div>'
      if (!opt.inline) {
        html +=
          '<div style="clear:both;height:0;font-size:0;"></div>' + '<div class="time">' + '<div class="time1"></div>'
        if (!opt.singleDate) {
          html += '<div class="time2"></div>'
        }
        html += '</div>' + '<div style="clear:both;height:0;font-size:0;"></div>' + '</div>'

        html += '<div class="footer">'
        if (opt.showShortcuts) {
          html += `<div class="shortcuts"><b>${lang('shortcuts')}</b>`

          var data = opt.shortcuts
          if (data) {
            if (data['prev-days'] && data['prev-days'].length > 0) {
              html += `&nbsp;<span class="prev-days">${lang('past')}`
              for (var i = 0; i < data['prev-days'].length; i++) {
                var name = data['prev-days'][i]
                name += data['prev-days'][i] > 1 ? lang('days') : lang('day')
                html += ` <a href="javascript:;" shortcut="day,-${data['prev-days'][i]}">${name}</a>`
              }
              html += '</span>'
            }

            if (data['next-days'] && data['next-days'].length > 0) {
              html += `&nbsp;<span class="next-days">${lang('following')}`
              for (var i = 0; i < data['next-days'].length; i++) {
                var name = data['next-days'][i]
                name += data['next-days'][i] > 1 ? lang('days') : lang('day')
                html += ` <a href="javascript:;" shortcut="day,${data['next-days'][i]}">${name}</a>`
              }
              html += '</span>'
            }

            if (data.prev && data.prev.length > 0) {
              html += `&nbsp;<span class="prev-buttons">${lang('previous')}`
              for (var i = 0; i < data.prev.length; i++) {
                var name = lang(`prev-${data.prev[i]}`)
                html += ` <a href="javascript:;" shortcut="prev,${data.prev[i]}">${name}</a>`
              }
              html += '</span>'
            }

            if (data.next && data.next.length > 0) {
              html += `&nbsp;<span class="next-buttons">${lang('next')}`
              for (var i = 0; i < data.next.length; i++) {
                var name = lang(`next-${data.next[i]}`)
                html += ` <a href="javascript:;" shortcut="next,${data.next[i]}">${name}</a>`
              }
              html += '</span>'
            }
          }
          if (opt.customShortcuts) {
            for (var i = 0; i < opt.customShortcuts.length; i++) {
              var sh = opt.customShortcuts[i]
              html += `&nbsp;<span class="custom-shortcut"><a href="javascript:;" shortcut="custom">${sh.name}</a></span>`
            }
          }
          html += '</div>'
        }
        // Add Custom Values Dom
        if (opt.showCustomValues) {
          html += `<div class="customValues"><b>${opt.customValueLabel || lang('custom-values')}</b>`

          if (opt.customValues) {
            for (var i = 0; i < opt.customValues.length; i++) {
              var val = opt.customValues[i]
              html += `&nbsp;<span class="custom-value"><a href="javascript:;" custom="${val.value}">${val.name}</a></span>`
            }
          }
        }
      }

      html += '</div>'
      html += '</div>'
      return $(html)
    }

    function getOfferLegend(opt) {
      return opt.offerLegend ? `<div class="offer-legend">${opt.offerLegendText}</div>` : ''
    }

    function getSelectedDatesLegend(opt) {
      var html = ''
      if (
        opt.selectedDatesLegendText &&
        opt.selectedDatesLegendText !== '' &&
        opt.selectCheckInLegendText !== '' &&
        opt.selectCheckOutLegendText !== ''
      ) {
        html += `<div class="dates-selected"> <label>${opt.selectedDatesLegendText}</label>`
        html += `<div><span class="date check-in-legend is-active" data-legend="${opt.selectCheckInLegendText}">${opt.selectCheckInLegendText}</span> <span class="separator"></span> <span class="date check-out-legend" data-legend="${opt.selectCheckOutLegendText}">${opt.selectCheckOutLegendText}</span></div>`
        html += '</div>'
      }

      return html
    }

    function getMultipleBookingLegend(opt) {
      var html = ''
      if (
        opt.multipleBookingText &&
        opt.multipleBookingText !== '' &&
        opt.multipleBookingUrl !== '' &&
        opt.multipleBookingUrl !== ''
      ) {
        html += '<div class="link-multiple-booking">'
        html += `<a href="${opt.multipleBookingUrl}">${opt.multipleBookingText}</a>`
        html += '</div>'
      }

      return html
    }

    function getMonthHead(date, opt) {
      var currentDate = new Date(),
        maxYear = currentDate.getFullYear() + 100,
        monthName = `${nameMonth(date.getMonth())} `,
        yearName = date.getFullYear(),
        colspan = typeof opt !== 'undefined' && opt.inline && opt.inline_mobile_panel ? 7 : 5,
        $wrapper =
          IB.currentDevice !== 'mobile'
            ? $('<div class="month-name th">').html(monthName)
            : $(`<th class="month-name" colspan="${colspan}">`).html(monthName),
        minYear = currentDate.getFullYear(),
        $select = $(`<select class="year-selector" tabindex="0" aria-label="${lang('current-year')}">`),
        $option = $('<option>'),
        options = []
      if (opt.selectBackward) {
        if (opt.maxYear) {
          maxYear = opt.maxYear
        } else {
          maxYear = currentDate.getFullYear()
        }
        if (opt.minYear) {
          minYear = opt.minYear
        } else {
          minYear = currentDate.getFullYear() - 100
        }
      }
      for (var i = minYear; i <= maxYear; i++) {
        options.push(
          $option
            .clone()
            .val(i)
            .text(i)
            .prop('selected', yearName == i)
        )
      }

      if (opt.singleDate) {
        return $wrapper.append($select.html(options))
      }
      return $wrapper.append($('<span>').text(yearName))
    }

    function getInlineMonthWrapper(month) {
      return `<table class="month ${month}" cellspacing="0" border="0" cellpadding="0"><thead><tr class="caption"><th colspan="7" class="month-name"></th></tr></thead><tbody></tbody></table>`
    }

    function getApplyBtnClass() {
      var klass = ''
      if (opt.autoClose === true) {
        klass += ' hide'
      }
      if (opt.applyBtnClass !== '') {
        klass += ` ${opt.applyBtnClass}`
      }
      return klass
    }

    function getWeekHead() {
      var prepend = opt.showWeekNumbers ? `<th>${lang('week-number')}</th>` : ''
      if (opt.startOfWeek == 'monday') {
        return (
          `${prepend}<th>${lang('week-1')}</th>` +
          `<th>${lang('week-2')}</th>` +
          `<th>${lang('week-3')}</th>` +
          `<th>${lang('week-4')}</th>` +
          `<th>${lang('week-5')}</th>` +
          `<th>${lang('week-6')}</th>` +
          `<th>${lang('week-7')}</th>`
        )
      }
      return (
        `${prepend}<th>${lang('week-7')}</th>` +
        `<th>${lang('week-1')}</th>` +
        `<th>${lang('week-2')}</th>` +
        `<th>${lang('week-3')}</th>` +
        `<th>${lang('week-4')}</th>` +
        `<th>${lang('week-5')}</th>` +
        `<th>${lang('week-6')}</th>`
      )
    }

    function isMonthOutOfBounds(month) {
      month = dayjs(month)
      if (opt.startDate && month.endOf('month').isBefore(opt.startDate)) {
        return true
      }
      if (opt.endDate && month.startOf('month').isAfter(opt.endDate)) {
        return true
      }
      return false
    }

    function getGapHTML() {
      var html = ['<div class="gap-top-mask"></div><div class="gap-bottom-mask"></div><div class="gap-lines">']
      for (var i = 0; i < 20; i++) {
        html.push(
          '<div class="gap-line">' +
            '<div class="gap-1"></div>' +
            '<div class="gap-2"></div>' +
            '<div class="gap-3"></div>' +
            '</div>'
        )
      }
      html.push('</div>')
      return html.join('')
    }

    function hasMonth2() {
      return !opt.singleMonth
    }

    function attributesCallbacks(initialObject, callbacksArray, today) {
      var resultObject = jQuery.extend(true, {}, initialObject)

      jQuery.each(callbacksArray, function (cbAttrIndex, cbAttr) {
        var addAttributes = cbAttr(today)
        for (var attr in addAttributes) {
          if (resultObject.hasOwnProperty(attr)) {
            resultObject[attr] += addAttributes[attr]
          } else {
            resultObject[attr] = addAttributes[attr]
          }
        }
      })

      var attrString = ''

      for (var attr in resultObject) {
        if (resultObject.hasOwnProperty(attr)) {
          attrString += `${attr}="${resultObject[attr]}" `
        }
      }

      return attrString
    }

    function daysFrom1970(t) {
      return Math.floor(toLocalTimestamp(t) / 86400000)
    }

    function toLocalTimestamp(t) {
      if (dayjs.isDayjs(t)) t = t.toDate().getTime()
      if (typeof t === 'object' && t.getTime) t = t.getTime()
      if (typeof t === 'string' && !t.match(/\d{13}/)) t = dayjs(t, opt.format).toDate().getTime()
      t = parseInt(t, 10) - new Date().getTimezoneOffset() * 60 * 1000
      return t
    }

    function createMonthHTML(d) {
      var days = []
      d.setDate(1)
      var lastMonth = new Date(d.getTime() - 86400000)
      var now = new Date()

      var dayOfWeek = d.getDay()
      if (dayOfWeek === 0 && opt.startOfWeek === 'monday') {
        // add one week
        dayOfWeek = 7
      }

      if (dayOfWeek > 0) {
        for (var i = dayOfWeek; i > 0; i--) {
          var day = new Date(d.getTime() - 86400000 * i)
          var valid = isValidTime(day.getTime())
          if (opt.startDate && compare_day(day, opt.startDate) < 0) valid = false
          if (opt.endDate && compare_day(day, opt.endDate) > 0) valid = false
          days.push({
            date: day,
            type: 'lastMonth',
            day: day.getDate(),
            time: day.getTime(),
            valid,
          })
        }
      }
      var toMonth = d.getMonth()
      for (var i = 0; i < 40; i++) {
        var today = dayjs(d).add(i, 'days').toDate()
        var valid = isValidTime(today.getTime())
        if (opt.startDate && compare_day(today, opt.startDate) < 0) valid = false
        if (opt.endDate && compare_day(today, opt.endDate) > 0) valid = false
        days.push({
          date: today,
          type: today.getMonth() == toMonth ? 'toMonth' : 'nextMonth',
          day: today.getDate(),
          time: today.getTime(),
          valid,
        })
      }
      var html = []
      for (var week = 0; week < 6; week++) {
        if (days[week * 7].type == 'nextMonth') break
        html.push('<tr>')
        for (var day = 0; day < 7; day++) {
          var _day = opt.startOfWeek == 'monday' ? day + 1 : day
          var today = days[week * 7 + _day]
          var highlightToday = dayjs(today.time).format('L') == dayjs(now).format('L')
          today.extraClass = ''
          today.tooltip = ''
          if (today.valid && opt.beforeShowDay && typeof opt.beforeShowDay === 'function') {
            var _r = opt.beforeShowDay(dayjs(today.time).toDate())
            today.valid = _r[0]
            today.extraClass = _r[1] || ''
            today.tooltip = _r[2] || ''
            if (today.tooltip !== '') today.extraClass += ' has-tooltip '
          }

          var todayDivAttr = {
            time: today.time,
            'data-tooltip': today.tooltip,
            'data-id': dayjs(today.date).format('DDMMYYYY'),
            class: `day ${today.type} ${today.extraClass} ${today.valid ? 'valid' : 'invalid'} ${
              highlightToday ? 'real-today' : ''
            }`,
            tabindex: today.valid ? '0' : '-1',
          }

          if (day === 0 && opt.showWeekNumbers) {
            html.push(
              `<td><div class="week-number" data-start-time="${today.time}">${opt.getWeekNumber(today.date)}</div></td>`
            )
          }

          html.push(
            `<td ${attributesCallbacks({}, opt.dayTdAttrs, today)}><button type="button" ${attributesCallbacks(
              todayDivAttr,
              opt.dayDivAttrs,
              today
            )}>${showDayHTML(today.time, today.day)}</button></td>`
          )
        }
        html.push('</tr>')
      }
      return html.join('')
    }

    function showDayHTML(time, date) {
      if (opt.showDateFilter && typeof opt.showDateFilter === 'function') return opt.showDateFilter(time, date)
      return date
    }

    function getLanguages() {
      if (opt.language == 'auto') {
        var language = navigator.language ? navigator.language : navigator.browserLanguage
        if (!language) return $.dateRangePickerLanguages['default']
        language = language.toLowerCase()
        for (var key in $.dateRangePickerLanguages) {
          if (language.indexOf(key) !== -1) {
            return $.dateRangePickerLanguages[key]
          }
        }
        return $.dateRangePickerLanguages['default']
      }
      if (opt.language && opt.language in $.dateRangePickerLanguages) {
        return $.dateRangePickerLanguages[opt.language]
      }
      return $.dateRangePickerLanguages['default']
    }

    /**
     * translate language string
     */
    function lang(t) {
      var _t = t.toLowerCase()
      var re = t in langs ? langs[t] : _t in langs ? langs[_t] : null
      var defaultLanguage = $.dateRangePickerLanguages['default']
      if (re == null) re = t in defaultLanguage ? defaultLanguage[t] : _t in defaultLanguage ? defaultLanguage[_t] : ''
      return re
    }

    function getDefaultTime() {
      var defaultTime = opt.defaultTime ? opt.defaultTime : new Date()

      if (opt.lookBehind) {
        if (opt.startDate && compare_month(defaultTime, opt.startDate) < 0)
          defaultTime = nextMonth(dayjs(opt.startDate).toDate())
        if (opt.endDate && compare_month(defaultTime, opt.endDate) > 0) defaultTime = dayjs(opt.endDate).toDate()
      } else {
        if (opt.startDate && compare_month(defaultTime, opt.startDate) < 0) defaultTime = dayjs(opt.startDate).toDate()
        if (opt.endDate && compare_month(nextMonth(defaultTime), opt.endDate) > 0)
          defaultTime = prevMonth(dayjs(opt.endDate).toDate())
      }

      if (opt.singleDate) {
        if (opt.startDate && compare_month(defaultTime, opt.startDate) < 0) defaultTime = dayjs(opt.startDate).toDate()
        if (opt.endDate && compare_month(defaultTime, opt.endDate) > 0) defaultTime = dayjs(opt.endDate).toDate()
      }

      return defaultTime
    }

    function resetMonthsView(time, opt) {
      if (!time) {
        time = getDefaultTime()
      }

      if (opt.singleDate) {
        showMonth(time, 'month1', opt)
      } else if (opt.lookBehind) {
        showMonth(prevMonth(time), 'month1', opt)
        showMonth(time, 'month2', opt)
      } else {
        var months_qty = box.find('.month').length
        for (var index = 0; index < months_qty; index++) {
          showMonth(dayjs(time).add(index, 'months').toDate(), `month${index + 1}`, opt)
        }
      }

      showSelectedDays()
      showGap()
    }
  }
})
