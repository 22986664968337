/* eslint-disable */
import '../lazy_img/lazy_img'

import { readCookie } from '../../../utils/cookie_manager'
import { exit_partner, getUrlVars } from '../../analytics/partner_set_cookies'
import { currentDevice } from '../../utils/currentDevice'
import '../../../vue/mobile_menu/mobile_menu_init'

let cp_promotional = isPromo()

let cp_tag, cp_image_tag, cp_partpdv_tag
readPartner()

let isUserPartner, cp, cp_image
updatePartner()

sharePartnersBetweenSites()

export function showPartnerLogo() {
  if (IB.is_in_microsite) {
    return;
  }
  if (!$('.exit-partner').hasClass('is-hidden') && !currentDevice.isMobile) {
    //Solo queremos ejecutar esta función una vez
    return
  }

  if (typeof getUrlVars()['NoPartner'] !== 'undefined') {
    //No queremos ejecutar esta función si ya estamos saliendo del partner
    return
  }
  // if (isHorizons() && !readCookie(cp_tag)) return;

  if (cp_image == null) {
    updatePartner()
  }

  if (cp_image && cp_image != 'null' && cp && cp_promotional === false) {
    const src = decodeURIComponent(cp_image)
    if (currentDevice.isMobile) {
      $('#partner-logo-mobile').removeClass('is-hidden')
      $('.js-img-partner').attr('src', src)
    } else if (currentDevice.isTablet) {
      $('.js-reb-header-wrapper').find('.logo .js-img-partner').addClass('is-hidden')
    } else if (currentDevice.isDesktop) {
      // Partner own logo
      $('.js-partner-logo .js-img-partner').attr('src', src)
      $('.main-header').find('.logo').addClass('logo--partner')
      // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
      $('.js-reb-header-wrapper').find('.logo').addClass('logo--partner')
      $('.js-reb-header-wrapper').find('.logo .js-imgs-container').addClass('reb-version')
      $('.js-reb-header-wrapper').find('.js-partner-logo').addClass('reb-version')
      // END TEST
      $('.main-header').find('.main-header-content').addClass('main-header-content--partner')
    }
    // Iberostar partners program logo
    $('.js-partner-logo').removeClass('is-hidden')
    IB.lazyImg.loadImg('.js-partner-logo .partner-logo-item--iberostar')
    // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
    $('.js-reb-header-wrapper .partner-logo-item-menu').removeClass('is-hidden')
    $('.js-reb-header-wrapper .partner-logo-item--iberostar').addClass('is-hidden')
    // END TEST
    $('.partner-logo-fbs').removeClass('is-hidden')
    $('.exit-partner').removeClass('is-hidden')

    if (isPartner()) {
      $('.js-partner-logo').removeClass('is-hidden')
      $('.js-partner-logo-mobile').removeClass('is-hidden')
    } else {
      $('.main-header').find('.logo').addClass('logo--partner')
      $('.main-header').find('.main-header-content').addClass('main-header-content--partner')
      $('.main-header > .wrapper-main-header').addClass('partner-header')
    }
    hiddenLogoIberostarWhite()
  } else {
    if (!isPartner()) {
      hidePartnerLogo()
    }
  }

  // Do not hide menu
  //if (partnerHasVisibilityMib() === false) {
  //  $('.user-options-link-wrapper').addClass('is-hidden');
  //} else {
  //  $('.user-options-link-wrapper').removeClass('is-hidden');
  //}
}

function hiddenLogoIberostarWhite() {
  $('.logo-iberostar-white').addClass('is-hidden')
  $('.logo-green').addClass('is-hidden')
  $('.logo-white').addClass('is-hidden')
}

export function hidePartnerLogo() {
  const microsite = document.documentElement.dataset.inMicrosite
  if (microsite !== 'false') {
    return
  }
  $('.js-partner-logo').addClass('is-hidden')
  $('#partner-logo-mobile').addClass('is-hidden')
  $('.js-partner-template').addClass('is-hidden')
  $('.exit-partner').addClass('is-hidden')
  $('#logo-iberostar, #logo-iberostar-white, #logo-green, #logo-white, .logo-green, .logo-white').removeClass(
    'is-hidden'
  )
  $('.main-header').find('.logo').removeClass('logo--partner')
  // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
  $('.js-reb-header-wrapper').find('.logo').removeClass('logo--partner')
  // END TEST

  $('.main-header').find('.main-header-content').removeClass('main-header-content--partner')
  $('.main-header > .wrapper-main-header').removeClass('partner-header')
  $('.user-options-link-wrapper').removeClass('is-hidden')
}

export function isPartner() {
  return !(typeof cp === 'undefined' || cp === null)
}

function partnerHasVisibilityMib() {
  const cp_partpdv = readCookie(cp_partpdv_tag)
  return cp_partpdv === undefined || cp_partpdv !== '0' || cp_partpdv === null
}

function isPromotionalPartner() {
  return isUserPartner && cp_promotional === 'true'
}

function isPromo() {
  return readCookie('cp_promotional') === 'true'
}

function readPartner() {
  cp_tag = cp_promotional ? 'cp' : 'cp_no_prom'
  cp_image_tag = cp_promotional ? 'cp_image' : 'cp_no_prom_image'
  cp_partpdv_tag = cp_promotional ? 'cp_partpdv' : 'cp_no_prom_partpdv'
}

function updatePartner() {
  cp = readCookie(cp_tag)
  cp_promotional = isPromo()
  cp_image = readCookie(cp_image_tag)
  isUserPartner = isPartner()
}

export function let_exit_partner() {
  $('.exit-partner').on('click', function (event) {
    event.preventDefault()
    if(currentDevice.isMobile && document.body.classList.contains('opened-menu')) {
      document.body.classList.remove('opened-menu')
      const header = document.querySelector('.js-mobile-header')
      if (header.classList.contains('fake-header-menu')) {
        header.classList.remove('fake-header-menu')
        header.classList.add('initial')
      }
      ScrollLock.off()
    }
    exit_partner(function () {
      hidePartnerLogo()
    })
  })
}

function sharePartnersBetweenSites() {
  if (window.location.href.indexOf('iberostar.com') === -1) {
    return
  }

  if (getUrlVars()['cp'] === undefined && $.cookie('cp') === undefined && $.cookie('cp_no_prom') === undefined) {
    return
  }

  const cp = getUrlVars()['cp'] || $.cookie('cp') || $.cookie('cp_no_prom')

  ;[
    'https://www.iberostarbeachfront.com',
    'https://www.iberostarhotels.com',
    'http://www.iberostarbeachfront.com',
    'http://www.iberostarhotels.com',
  ].forEach(function (s) {
    document.querySelectorAll(`[href^="${s}"]`).forEach(function (u) {
      let href = u.href
      href += href.includes('?') ? '&' : '?'
      href += `cp=${cp}`
      u.setAttribute('href', href)
    })
  })
}

window.IB.partner = {
  showPartnerLogo,
  hidePartnerLogo,
  let_exit_partner,
  isPartner,
  isPromotionalPartner,
  updatePartner,
  partnerHasVisibilityMib,
}

IB.partner.showPartnerLogo()
IB.partner.let_exit_partner()
