import '../../utils/scroll_lock'

import { isPartner } from '../../../src/core/iberapi/partner_shower/partner_shower'

const menuToggleClass = '.mobile-menu-toggle'
const menuHeaderClass = IB.currentDevice === 'mobile' ? '.mobile-header' : '.main-header'
const contentMenuHeaderClass = IB.currentDevice === 'mobile' ? 'body .page-wrapper' : 'body'
let menuInitialized = false
const accessibility_mode = IB.a11y.isAdaActivated() || false
$(document).one('click', `${menuToggleClass}:not(.ibp-toggle)`, function (e) {
  e.preventDefault()
  const $el = $(this)

  if (!menuInitialized && !document.querySelector('body.mice-home')) {
    menuInitialized = true

    import(
      /* webpackChunkName: 'vue_mobile_menu' */
      /* webpackPreload: true */
      `./vue_mobile_menu`
    )
      .then(blockJS => {
        blockJS.initMobileMenu()
      })
      .catch(() => {})
  }
})

/* Basic events to open and close menu */
$(document).on('click', `${menuToggleClass}:not(.ibp-toggle)`, function (e) {
  e.preventDefault()

  if (document.querySelector('body.mice-home')) {
    toggleMenu(e)
  } else {
    $('body').addClass('opened-menu')

    if (IB.currentDevice !== 'mobile') {
      $(menuHeaderClass).addClass('js-header-move')
    }

    $(contentMenuHeaderClass).prepend($(menuToggleClass).closest(menuHeaderClass))

    if (IB.currentDevice === 'mobile') {
      addFakeHeaderWhenIsTransparent('.mobile-header')
      showHeaderMobileWithMenu('.mobile-header')
    } else if (IB.currentDevice === 'tablet') {
      addFakeHeaderWhenIsTransparent('.main-header')
    }
    LogosMenuOpenManagement()

    ScrollLock.on()
  }
})

function toggleMenu(event) {
  event.preventDefault()
  $('html').toggleClass('opened-left')
}

function LogosMenuOpenManagement() {
  if (isPartner()) {
    showPartnerLogoMenu()
    return
  }
  showIberostarLogoMenu()
}

function LogosMenuCloseManagement() {
  if (isPartner()) {
    hiddenPartnerLogoMenu()
    return
  }
  hiddenIberostarLogoMenu()
}

function showIberostarLogoMenu() {
  if (IB.currentDevice === 'tablet' && accessibility_mode === false) {
    $('#logo-iberostar-white').addClass('is-hidden')
  }
  if (IB.currentDevice === 'tablet' && accessibility_mode === true) {
    $('#logo-green').addClass('is-hidden')
  }
}

function hiddenIberostarLogoMenu() {
  if (IB.currentDevice === 'tablet' && accessibility_mode === false) {
    $('#logo-iberostar-white').removeClass('is-hidden')
  }
  if (IB.currentDevice === 'tablet' && accessibility_mode === true) {
    $('#logo-green').removeClass('is-hidden')
  }
}

function showPartnerLogoMenu() {
  if (IB.currentDevice === 'tablet' && accessibility_mode === false) {
    $('.partner-logo-item--iberostar').addClass('is-hidden')
    $('.partner-logo-item-menu').removeClass('is-hidden')
  } else if (IB.currentDevice === 'mobile') {
    $('#partner-logo-mobile .partner-logo-item-menu').removeClass('is-hidden')
    $('#partner-logo-mobile .partner-logo-item--iberostar').addClass('is-hidden')
  }
}

function hiddenPartnerLogoMenu() {
  $('.partner-logo-item--iberostar').removeClass('is-hidden')
  $('.partner-logo-item-menu').addClass('is-hidden')
}

$(document).on('click', `.mobile-menu-blocker, .opened-menu ${menuToggleClass}`, function (e) {
  e.preventDefault()

  if (document.querySelector('body.mice-home')) return false

  $('body').removeClass('opened-menu')

  if (IB.currentDevice !== 'mobile') {
    $(menuHeaderClass).removeClass('js-header-move')
  }

  const $header = $(`body > ${menuHeaderClass}`).remove()
  if ($('.headband').length) {
    $($header).insertAfter($('.headband'))
  } else {
    $('.page-wrapper').prepend($header)
  }

  if (IB.currentDevice === 'mobile') {
    removeStickyMenuWithHeadband('.mobile-header')
    removeFakeHeaderWhenIsTransparent('.mobile-header')
  } else if (IB.currentDevice === 'tablet') {
    removeFakeHeaderWhenIsTransparent('.main-header')
  }

  LogosMenuCloseManagement()

  ScrollLock.off()
})

function addFakeHeaderWhenIsTransparent(headerClass) {
  const header = document.querySelector(headerClass)
  if (header && header.classList.contains('initial')) {
    header.classList.add('fake-header-menu')
    header.classList.remove('initial')
  }
}

function removeFakeHeaderWhenIsTransparent(headerClass) {
  const header = document.querySelector(headerClass)
  if (header && header.classList.contains('fake-header-menu')) {
    header.classList.remove('fake-header-menu')
    header.classList.add('initial')
  }
}

function showHeaderMobileWithMenu() {
  const stickyHeaderWithHeadband = document.querySelector(
    '.mobile-header.js-mobile-header.mobile-sticky-container.pushed-by-sticky-headbands.nav-down.sticky'
  )
  if (stickyHeaderWithHeadband) {
    stickyHeaderWithHeadband.classList.remove('pushed-by-sticky-headbands')
    stickyHeaderWithHeadband.classList.add('fake-header-menu')
    stickyHeaderWithHeadband.classList.add('with-headband')
  }
}

function removeStickyMenuWithHeadband() {
  const stickyMenuWithHeadband = document.querySelector('.mobile-header.js-mobile-header.with-headband')

  if (stickyMenuWithHeadband) {
    stickyMenuWithHeadband.classList.add('pushed-by-sticky-headbands')
    stickyMenuWithHeadband.classList.add('nav-down')
    stickyMenuWithHeadband.classList.add('sticky')
    stickyMenuWithHeadband.classList.remove('fake-header-menu')
  }
}
