

let IBEROSTAR_PRO_COOKIE_NAME = 'iberpro';
let USER_FIELD_NAME = 'user_email_md5';

function is_partner() {
  return IB.partner.isPartner();
}

function is_agency() {
  let pro_cookie = $.cookie(IBEROSTAR_PRO_COOKIE_NAME);

  return typeof pro_cookie !== 'undefined';
}

function is_user() {
  let utag_user = sessionStorage.getItem('utag_user');
  if (utag_user === undefined || utag_user === null || !utag_user.length) return false;
  let user_mail = JSON.parse(utag_user)[USER_FIELD_NAME];

  return user_mail !== null && user_mail !== undefined;
}


window.IB.visitor_type_detector = {
  is_partner: is_partner,
  is_agency:  is_agency,
  is_user:    is_user
};
