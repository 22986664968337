import { getUrlVars } from './partner_set_cookies';
import { setCookie, setCookies } from '../../utils/cookie_manager';

(function ($, undefined) {
  if (typeof IB === 'undefined') {
    window.IB = {};
  } else if (IB.UtmCampaign !== undefined) {
    return;
  } else if (IB.VisitorSessionId !== undefined) {
    return;
  }

  // time expiration visitor_session_id
  let minutes = 30;
  let base_domain = document.domain.split('.').slice(-2)
    .join('.');

  /*
   * auxiliar function to set cookies
   * if value is undefined or null, this value should change by blank value.
   */
  function setCookieDomain(key, value) {
    let base_domain = document.domain.split('.').slice(-2)
      .join('.');
    setCookie(key, value || '', null, null, '.' + base_domain);
  }

  /*
   * return: true if there are NOT utm parameters
   * return: fase if there are some utm parameter
   */
  function blankUtmParameters() {
    let url_utm_source = getUrlVars()['utm_source'];
    let url_utm_medium = getUrlVars()['utm_medium'];
    let url_utm_name = getUrlVars()['utm_campaign'];
    let url_utm_content = getUrlVars()['utm_content'];

    return undefined === url_utm_source &&
           undefined === url_utm_medium &&
           undefined === url_utm_name &&
           undefined === url_utm_content;
  }

  /*
   * return: true if any of utm parameters differents of cookies values
   * return: fase if all parameters values are equal of cookies values
   */
  function hasChangedValuesUtmParameters() {
    let url_utm_source = getUrlVars()['utm_source'];
    let url_utm_medium = getUrlVars()['utm_medium'];
    let url_utm_name = getUrlVars()['utm_campaign'];
    let url_utm_content = getUrlVars()['utm_content'];

    if (url_utm_source === undefined) {url_utm_source = '';}
    if (url_utm_medium === undefined) {url_utm_medium = '';}
    if (url_utm_name === undefined) {url_utm_name = '';}
    if (url_utm_content === undefined) {url_utm_content = '';}

    return $.cookie('campaign_source') !== url_utm_source ||
           $.cookie('campaign_medium') !== url_utm_medium ||
           $.cookie('campaign_name') !== url_utm_name ||
           $.cookie('campaign_content') !== url_utm_content;
  }

  /*
   * Set utm cookies and utag_data whit utm params.
   * if the param is undefined, default value will be blank ('')
   */
  function setUtmCampaignDatasFromUrl() {
    // set values on utag_data
    utag_data['campaign_source'] = getUrlVars()['utm_source'] || '';
    utag_data['campaign_medium'] = getUrlVars()['utm_medium'] || '';
    utag_data['campaign_name'] = getUrlVars()['utm_campaign'] || '';
    utag_data['campaign_content'] = getUrlVars()['utm_content'] || '';

    let domain = '.' + document.domain.split('.').slice(-2).join('.');
    setCookies([
      {'name': 'campaign_source', 'value': utag_data['campaign_source'], 'domain': domain},
      {'name': 'campaign_medium', 'value': utag_data['campaign_medium'], 'domain': domain},
      {'name': 'campaign_name', 'value': utag_data['campaign_name'], 'domain': domain},
      {'name': 'campaign_content', 'value': utag_data['campaign_content'], 'domain': domain}
    ]);
  }

  // Reset all utm utag_datas with cookies or blank values if cookies not exists.
  function setUtmCampaignDatasFromCookies() {
    // set values on uatg_data from cookies
    utag_data['campaign_medium'] = $.cookie('campaign_medium') || '';
    utag_data['campaign_name'] = $.cookie('campaign_name') || '';
    utag_data['campaign_source'] = $.cookie('campaign_source') || '';
    utag_data['campaign_content'] = $.cookie('campaign_content') || '';
  }

  // Return if exists a visitor_session_id cookie not expired.
  function hasCurrentVisitorSessionId() {
    return $.cookie('visitor_session_id') !== null && $.cookie('visitor_session_id') !== undefined;
  }

  function needsUpdateSessionExpire(date) {
    return $.cookie('visitor_session_id_expire') !== null && $.cookie('visitor_session_id_expire') !== undefined &&
            (date - Date.parse($.cookie('visitor_session_id_expire'))) > 90000;
  }

  /*
   * Session variable
   * if we have a current visitor_session_id (no expired), we get the cookie value and update expiration time
   * if not, we create a new one.
   */
  function initNewVisitorSessionId(force) {
    let visitor_session_id;
    let hasCurrentSession = hasCurrentVisitorSessionId();
    let date = new Date();
    date.setTime(date.getTime() + (minutes * 60 * 1000));


    if (hasCurrentSession && force !== true) {
      visitor_session_id = $.cookie('visitor_session_id');
    } else {
      visitor_session_id =
        Math.random().toString(36)
          .substring(2, 10) +
        Math.random().toString(36)
          .substring(2, 10);
    }
    utag_data['visitor_session_id'] = visitor_session_id;
    if (!hasCurrentSession || force == true || needsUpdateSessionExpire(date)) {
      setCookies([
        {'name': 'visitor_session_id', 'value': visitor_session_id, 'expires': date, 'domain': '.' + base_domain},
        {'name': 'visitor_session_id_expire', 'value': date, 'expires': date, path: "/"}
      ]);
    }
  }

  // Set campaign_cp variable cookie and utag_data with params value or blank value.
  function setCampaignCpFromUrl() {
    setCookieDomain('campaign_cp', getUrlVars()['cp']);
    // set values on uatg_data from cookies
    utag_data['campaign_cp'] = getUrlVars()['cp'];
  }
  /*
   * Set campaign_cp variable cookie and utag_data with params value or blank value.
   * if campaign_cp is undefined param, the new value should be the last cookie value.
   */
  function setCampaignCpFromUrlFromCookie() {
    let campaign_cp = getUrlVars()['cp'];
    // set values on uatg_data from cookies
    if (campaign_cp !== undefined && campaign_cp !== null) {setCookieDomain('campaign_cp', campaign_cp);}

    utag_data['campaign_cp'] = campaign_cp || $.cookie('campaign_cp') || '';
  }

  IB.UtmCampaign = {
    hasChangedValuesUtmParameters:  hasChangedValuesUtmParameters,
    blankUtmParameters:             blankUtmParameters,
    setUtmCampaignDatasFromUrl:     setUtmCampaignDatasFromUrl,
    setUtmCampaignDatasFromCookies: setUtmCampaignDatasFromCookies,
    printCampaignDatas:             printCampaignDatas
  };

  IB.CpCampaign = {
    setCampaignCpFromUrl:           setCampaignCpFromUrl,
    setCampaignCpFromUrlFromCookie: setCampaignCpFromUrlFromCookie
  };

  IB.VisitorSessionId = {
    initNewVisitorSessionId: initNewVisitorSessionId

  };

  // DELETE AFTER UPLOAD PRODUCCTION
  function printCampaignDatas() {
    console.log('visitor_session_id -> ' + utag_data['visitor_session_id'] + '\n' +
      'visitor_session_expired -> ' + (hasCurrentVisitorSessionId() === false) + '\n' +
      'visitor_session_expired -> ' + $.cookie('visitor_session_id_expire') + '\n' +
      'campaign_medium -> ' + utag_data['campaign_medium'] + '\n' +
      'campaign_name -> ' + utag_data['campaign_name'] + '\n' +
      'campaign_source -> ' + utag_data['campaign_source'] + '\n' +
      'campaign_content -> ' + utag_data['campaign_content'] + '\n' +
      'campaign_cp -> ' + utag_data['campaign_cp'] + '\n');
  }
}(jQuery));

// Init visitor_session_id
/*
 * ******************************************************************************************
 * UTM VARIABLES, CAMPAIGN CP AND VISITOR_SESSION_ID
 * - if we have utm params and any of them is changed
 *  - force create a new session_visitor_id
 *  - Reset utm values from url (if value is undefined, we set blank value)
 *  - Reset campaign_cp value from url (if value is undefined, we set blank value)
 * - else
 *  - Create a new visitor_session_id if this is expired.
 *  - Read utm values from cookies
 *  - Read campaign_cp value from url and if this value is undefined, we get cookie value
 *
 * variable Campaign_cp
 * - Get value from url if any utm variables changes, if there are not url param the value will be blank
 * - if there are not utm params we get values from url or cookie.
 * - Just be changed when utm variables change, if not set a cookie value or blank value.
 *
 * visitor_session_id
 * - Just be change when there are not interaction during 30 minutes.
 * - Exists any utm params and Any of them is changed
 * - End of day.
 *********************************************************************************************
 */
$(function () {
  // if we have utm params and any of them is changed
  if (!IB.UtmCampaign.blankUtmParameters() && IB.UtmCampaign.hasChangedValuesUtmParameters()) {
    // Force new visitor_session_id
    IB.VisitorSessionId.initNewVisitorSessionId(true);
    IB.UtmCampaign.setUtmCampaignDatasFromUrl();
    IB.CpCampaign.setCampaignCpFromUrl();
  } else {
    // set visitor_session_id whitout force
    IB.VisitorSessionId.initNewVisitorSessionId();
    IB.CpCampaign.setCampaignCpFromUrlFromCookie();
    IB.UtmCampaign.setUtmCampaignDatasFromCookies();
  }
});
