import '../../vendors/jquery/jquery.scrollTo'

const $document = $(document)
const $html = $('html')
const close_tpl = $(
  '<button type="button" class="apply-btn close-mobile-panel js-close-panel" data-close-panel><span class="s s-close-fat"></span></button>'
)
const defaults = {
  from_position: 'right',
}
let distance
const in_mobile = IB.currentDevice === 'mobile'
const isFhiPage = document.querySelector('html').dataset.fhiTest !== undefined

function MobilePanel(element, options) {
  this.element = element
  this.options = $.extend({}, defaults, options)

  this.init()
}

MobilePanel.prototype.init = function () {
  const $el = $(this.element)
  const opts = this.options
  const from_position = typeof $el.data('panel-from') === 'undefined' ? opts.from_position : $el.data('panel-from')
  if ($el.find('.close-mobile-panel').length < 1) {
    if ($el.hasClass('date-picker-wrapper') || $el.attr('id') === 'people-mobile-panel') {
      const $date_info = $el.find('.dates-info')
      const $week_names = $el.find('.week-names')
      const $people_resume = $el.find('.people-mobile-resume')

      if ($date_info.length) {
        $date_info.prepend(
          $(
            '<div class="panel-top-control"><button type="button" class="return-btn" data-close-panel></button></div>'
          ).append(close_tpl.clone())
        )
      } else {
        $el.prepend(
          $(
            '<div class="panel-top-control"><button type="button" class="return-btn" data-close-panel></button></div>'
          ).append(close_tpl.clone())
        )
      }
    } else {
      $el.append(close_tpl.clone())
    }
  }
  $el.addClass(from_position)
  if (!$el.parent().is('body')) {
    $el.remove()
    $('body').append($el)
  }
  $el.addClass('ready')
}

$document.on('click', '.open-mobile-panel', function (event) {
  event.preventDefault()
  const $this = $(this)
  if ($this.attr('data-remote')) {
    $($this.attr('data-remote-panel-href')).addClass('opened')
  } else if ($this.attr('href')) {
    $($this.attr('href')).addClass('opened')
  } else {
    $($this.attr('data-target')).addClass('opened')
  }

  $html.addClass('mobile-panel-opened')
  if (!isFhiPage) ScrollLock.on()

  if (in_mobile && $this.hasClass('js-filter-list-link') && $('.filtering-container').length) {
    ScrollLock.off() // let the window scroll
    distance = $('.filtering-container').offset().top
  }
})

$document.on('click', '[data-close-panel]', function (event) {
  event.preventDefault()
  const $this = $(this)
  const $panel = $this.closest('.mobile-panel')
  let $this_trigger

  // Select trigger link that opens panel and set aria-selected to false when we click on close panel
  if ($panel.length) {
    $this_trigger = $(`[href=#${$panel.attr('id')}]`)
  }
  if ($this_trigger.length) {
    $this_trigger.attr('aria-selected', false)
  }

  const wasOpened = $panel.hasClass('opened')
  $panel.removeClass('opened opened-picker')
  if (
    !$('.mobile-panel.opened').length ||
    ($this.hasClass('apply-btn') && $panel.attr('id') === 'people-mobile-panel')
  ) {
    if (in_mobile && $this.hasClass('apply-filters') && distance !== undefined) {
      $document.scrollTo(distance - ($('.filters.sticky').height() + $('.mobile-header').height() + 50))
    }
    $('.mobile-panel').removeClass('opened')
    $('.mobile-fastbooking-bg').remove()
    $html.removeClass('mobile-panel-opened')
    wasOpened && ScrollLock.off()
  }
})

$.fn.mobilepanel = function (options) {
  return this.each(function () {
    if (!$.data(this, 'plugin_mobilepanel')) {
      $.data(this, 'plugin_mobilepanel', new MobilePanel(this, options))
    }
  })
}
